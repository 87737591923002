import { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactModal = ({ show, onHide, isToggled, theme }) => {

    const [formData, setFormData] = useState({
        from_name: '',
        contact_info: '',
        message: '',
      });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const resetFields = () => {
        setFormData({
            from_name: '',
            contact_info: '',
            message: '',
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Handle form submission logic here
        console.log(formData);

        // Clear form fields if needed
        resetFields();

        // Close modal after form submission
        onHide();

        emailjs
            .send(
                'service_ie1e411', //service ID
                'template_mbw9euo', //template ID
                formData,
                'brZF2RUhMEQ-PZ6YQ' //EmailJS public key
            )
            .then(
                (response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Your message has been sent!');
                },
                (err) => {
                console.log('FAILED...', err);
                alert('Failed to send your message. Please try again later.');
                }
            );

        
    };

    // Styles for modal and its components
    const modalContainerStyles = {
        display: show ? 'block' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 999
    };

    const modalContentStyle = {
        margin: '15% auto',
        padding: '20px',
        width: '650px',
        height: '400px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
        position: 'relative'
    };
    

    const modalContentStylesThemed = {
        apple: {
            ...modalContentStyle,
            backgroundColor: isToggled ? 'rgb(28, 28, 30)' : 'rgb(245, 245, 245)',
            boxShadow: isToggled ? '0px 0px 15px rgba(0, 0, 0, 0.3)' : '0px 0px 15px rgba(0, 0, 0, 0.3)',
            borderRadius: '30px'
        },
        google: {
            ...modalContentStyle,
            background: isToggled ? 'radial-gradient(circle, rgb(40, 49, 66), rgb(27, 33, 45))' 
            : 'radial-gradient(circle, rgb(212, 214, 250), rgb(230, 231, 255))',
            borderRadius: '30px'
        },
        anon: {
            ...modalContentStyle,
            backgroundColor: isToggled ? 'rgb(19, 19, 19)' : 'rgba(255, 255, 255, 0.95)',
            border: isToggled ? '1px solid white' : '1px solid black'
        }
    };

    const titleStyles = {
        apple: {
            color: isToggled ? 'rgb(218,218,218)' : 'rgb(0,0,0)',
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            textAlign: 'center',
            fontSize: '24px',
            marginTop: '0px',
            marginBottom: '25px'
        },
        google: {
            color: isToggled ? 'rgb(218,218,218)' : 'rgb(0,0,0)',
            fontFamily: 'ProductSansLight, sans-serif',
            textAlign: 'center',
            fontSize: '24px',
            marginTop: '0px',
            marginBottom: '25px'
        },
        anon: {
            color: isToggled ? 'rgb(218,218,218)' : 'rgb(0,0,0)',
            textTransform: 'uppercase',
            fontFamily: 'unicaone, sans-serif',
            textAlign: 'center',
            fontSize: '24px',
            marginTop: '0px',
            marginBottom: '25px'
        }
    }

    const closeButtonStyle = {
        color: '#aaa',
        position: 'absolute',
        top: '0px', // Adjust top position as needed
        right: '15px', // Adjust right position as needed
        fontSize: '40px',
        cursor: 'pointer',
    };

    const formContainerStyle = {
        marginTop: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    };

    //-------------contact info--------------------------------------------
    const contactInfoStyle = {
        marginRight: '20px',
        paddingTop: '10px',
        width: '240px',
        height: '220px',
        textAlign: 'center',
        
    };

    const contactInfoStyles = {
        apple: {
            ...contactInfoStyle,
            backgroundColor: isToggled ? 'rgb(44, 44, 46)' : 'rgb(235, 235, 235)',
            borderRadius: '20px'
        },
        google: {
            ...contactInfoStyle,
            backgroundColor: isToggled ? 'rgb(40, 49, 66)' : 'rgba(192, 195, 252, 0.4)',
            borderRadius: '20px',
            border: isToggled ? '1px solid rgba(60, 143, 227, 0.2)' : '1px solid rgba(57, 69, 158, 0.2)',
            boxShadow: isToggled ? '0px 0px 5px rgb(0,0,0)' : '0px 0px 5px rgba(0,0,0,0.4)'
        },
        anon: {
            ...contactInfoStyle,
            backgroundColor: isToggled ? 'rgb(30,30,30)' : 'rgb(245,245,245)',
            border: isToggled ? '1px solid rgba(255, 255, 255, 0.2)' : '1px solid rgba(0, 0, 0, 0.1)'
        }
    };

    const imageStyles = {
        borderRadius: '50%',
        width: '100px', 
        height: '100px', 
        marginBottom: '10px',
    };

    const fonts = {
        apple: 'SF-Pro-Text-Thin, sans-serif',
        google: 'ProductSansLight, sans-serif', 
        anon: 'unicaone, sans-serif'
    };

    const emailAddressStyles = {
        fontFamily: fonts[theme],
        color: isToggled ? 'rgb(218,218,218)' : 'rgb(0,0,0)',
        fontSize: '15px',
        margin: '0px',
        textTransform: theme === 'anon' ? 'uppercase' : 'none'
    };

    const phoneNumberStyles = {
        fontFamily: fonts[theme],
        color: isToggled ? 'rgb(218,218,218)' : 'rgb(0,0,0)',
        fontSize: '18px',
        marginBottom: '5px'
    };

    const linkedinLogoStyles = {
        width: '90px',
        height: '30px',
        borderRadius: '5px',
    };
    //---------------------------------------------------------

    //-------------form--------------------------------------------
    const formStyle = {
        display: 'flex',
        width: '410px',
        flexDirection: 'column',
        alignItems: 'center'
    };

    const inputStyle = {
        apple: {
            fontFamily: 'SF-Pro-Text-Regular, sans-serif',
            marginBottom: '16px',
            padding: '8px 14px',
            borderRadius: '10px',
            fontSize: '14px',
            color: isToggled ? 'white' : 'black',
            backgroundColor: isToggled ? 'rgb(44, 44, 46)' : 'rgb(235, 235, 235)',
            border: 'none',
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            marginBottom: '16px',
            padding: '8px 14px',
            borderRadius: '10px',
            fontSize: '16px',
            color: isToggled ? 'white' : 'black',
            backgroundColor: isToggled ? 'rgb(39, 48, 71)' : 'rgba(192, 195, 252, 0.4)',
            border: isToggled ? '1px solid rgba(60, 143, 227, 0.2)' : '1px solid rgba(57, 69, 158, 0.2)',
        },
        anon: {
            fontFamily:'unicaone, sans-serif',
            textTransform: 'uppercase',
            marginBottom: '16px',
            padding: '6px 8px',
            color: isToggled ? 'white' : 'black',
            backgroundColor: isToggled ? 'rgb(44, 44, 46)' : 'rgb(235, 235, 235)',
            border: '1px solid rgba(0,0,0,0)',
            fontSize: '14px',
        }
        
    };

    const buttonStyle = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '16px',
            cursor: 'pointer',
            marginTop: '10px',
            color: 'white',
            backgroundColor: isToggled ? 'rgba(85, 85, 85)' : 'rgb(124, 124, 126)' ,
            padding: '8px 12px',
            borderRadius: '20px',
            border: isToggled ? '1px solid rgba(180,180,180,0.3)' : '1px solid rgba(20,20,20,0.3)'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '16px',
            cursor: 'pointer',
            marginTop: '10px',
            color: 'rgba(255,255,255,0.8)',
            backgroundColor: isToggled ? 'rgba(85, 85, 85, 0.6)' : 'rgb(164, 164, 166)' ,
            padding: '4px 12px',
            borderRadius: '20px',
            border: isToggled ? '1px solid rgba(180,180,180,0.3)' : '1px solid rgba(20,20,20,0.3)'
        },
        anon: {
            fontFamily: 'unicaone, sans-serif',
            textTransform: 'uppercase',
            fontSize: '16px',
            cursor: 'pointer',
            marginTop: '10px',
            color: 'white',
            backgroundColor: isToggled ? 'rgba(85, 85, 85)' : 'rgb(124, 124, 126)' ,
            padding: '4px 12px',
            border: isToggled ? '1px solid rgba(180,180,180,0.3)' : '1px solid rgba(20,20,20,0.3)'
        }
    };
    //---------------------------------------------------------

    return (
        <div style={modalContainerStyles}>

            <div style={modalContentStylesThemed[theme]}>

                <span style={closeButtonStyle} onClick={() => {onHide();resetFields();
                }}>&times;</span>

                <h2 style={titleStyles[theme]}>Get in Touch</h2>

                <div style={formContainerStyle}>

                    <div style={contactInfoStyles[theme]}>
                        <img src="misc/headshot.jpg" alt="Profile" style={imageStyles} />
                        <p style={emailAddressStyles}>connorsottosanti@gmail.com</p>
                        <p style={phoneNumberStyles}>(908) 472-3258</p>
                        <a href='https://www.linkedin.com/in/connor-sottosanti-3725201b1'>
                            <img src="LinkedInLogo.png" alt="li-logo" style={linkedinLogoStyles} />
                        </a>
                    </div>

                    <form style={formStyle} onSubmit={handleSubmit}>
                        
                        <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                            <input
                                placeholder="Name"
                                type="text"
                                name="from_name"
                                value={formData.from_name}
                                onChange={handleChange}
                                style={{...inputStyle[theme], width: '150px'}}
                            />
                            <input
                                placeholder="Email / Phone"
                                type="text"
                                name="contact_info"
                                value={formData.contact_info}
                                onChange={handleChange}
                                style={{...inputStyle[theme], width: '180px'}}
                                required
                            />
                        </div>
                        
                    
                        <textarea
                            rows={3}
                            placeholder="Enter your message"
                            name='message'
                            value={formData.message}
                            onChange={handleChange}
                            style={{...inputStyle[theme], width: '340px', resize: 'none'}}
                            required
                        />

                        <button type="submit" style={buttonStyle[theme]}>
                            Send Message
                        </button>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactModal;
