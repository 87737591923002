import '../../styles/ProjectPages/ProjectOnePage.css';
import '../../styles/ProjectPages/JoystiqSite.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const PocketCipherSite = ({ isToggled, theme }) => {

    useEffect(() => {
        window.scrollTo(0, 0); // Reset scroll position when this component mounts
    }, []);
    
    //back button
    const backButtonStyles = {
        apple: {
            background: 'none',
            border: 'none',
            color: 'rgb(10, 132, 255)',
            fontSize: '20px',
            cursor: 'pointer'
        },
        google: {
            background: 'none',
            border: 'none',
            color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(255, 255, 255)',
            fontSize: '20px',
            cursor: 'pointer'
        },
        anon: {
            marginTop: '20px',
            fontFamily: 'unicaone, sans-serif',
            position: 'relative',
            zIndex: 2,
            fontSize: '18px',
            cursor: 'pointer',
            backgroundColor: (isToggled ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.8'), 
            color: (isToggled ? 'black' : 'white'),
            border: isToggled ? '2px solid black': '2px solid white',
        }
    };

    const visitButtonStyles = {
        width: '70px',
        height: '10px',
        marginRight: '80px',
        background: 'none',
        paddingBottom: theme === 'anon' ? '30px' : 'none',
        border: theme === 'anon' ? (isToggled ? '1px solid white' : '1px solid black') : 'none',
        color: theme === 'anon' ? (isToggled ? 'white' : 'black') : 'rgb(10, 132, 255)',
        fontSize: '20px',
        cursor: 'pointer',
    };

    const projectOnePageStyles = {
        apple: {
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
            width: '1000px',
            minWidth: '900px',
            paddingBottom: '50px',
            borderRadius: '30px',
            backgroundColor: isToggled ? 'rgb(44, 44, 46)' : 'rgb(255, 255, 255)',
            boxShadow: isToggled ? '0px 0px 25px rgba(0, 0, 0, 0.3)' : '0px 0px 25px rgba(0, 0, 0, 0.3)',
            transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
        },
        google: {
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
            width: '1000px',
            minWidth: '900px',
            paddingBottom: '50px',
            borderRadius: '30px',
            backgroundColor: isToggled ? 'rgb(33, 39, 54)' : 'rgb(230, 231, 255)',
            boxShadow: isToggled ? '0px 0px 25px rgba(0, 0, 0, 0.3)' : '0px 0px 25px rgba(0, 0, 0, 0.3)',
            transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
        },
        anon: {
            zIndex: '2',
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            width: '1000px',
            minWidth: '900px',
            paddingBottom: '50px',
            marginBottom: '30px',
            backgroundColor: isToggled ? 'rgba(19, 19, 19, 0.9)' : 'rgba(255, 255, 255, 0.9)',
            border: `2px solid ${isToggled ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.8)'}`,
            boxShadow: isToggled
                ? '0px 0px 25px rgba(0, 0, 0, 0.9)'
                : '0px 0px 25px rgba(0, 0, 0, 0.9)',
            transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
        }
    };

    const pcBannerStyles = {
        width: theme === 'anon' ? '996px' : '1000px',
        height: '200px',
        background: 'linear-gradient(10deg, rgba(77, 41, 151, 0.9), rgba(190, 50, 251, 0.9))',
        borderTopLeftRadius: theme === 'anon' ? '0': '30px',
        borderTopRightRadius: theme === 'anon' ? '0' : '30px',
        borderBottom: theme === 'anon' ? (isToggled ? '1px solid rgba(255,255,255,0.2)' :'2px solid rgb(0,0,0)') : 'none'
    };
    
    const pcBannerTitleStyle = {
        marginLeft: '380px',
        marginTop: '70px',
        fontFamily: "'SF-Pro-Display-Thin', sans-serif",
        fontSize: '48px',
        backgroundImage: 'linear-gradient(to bottom, #ffeb7c, #716000)',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        color: 'transparent',
    };
      
    const appNameStyles = {
        apple: {
            margin: '40px 0px 0px 100px',
            fontFamily:'SF-Pro-Text-Thin, sans-serif',
            fontSize: '30px',
            color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(44, 44, 46)',
        },
        google :{
            margin: '40px 0px 0px 100px',
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '30px',
            color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(44, 44, 46)',
        },
        anon: {
            margin: '40px 0px 0px 100px',
            fontFamily: 'unicaone, sans-serif',
            textTransform: 'uppercase',
            fontSize: '30px',
            color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(44, 44, 46)',
        }
    };

    const titleDividerStyles = {
        apple: {
            margin: '5px 0px 30px 105px',
            height: '4px',
            borderRadius: '3px',
            width: '370px',
            background:'linear-gradient(to right, rgba(166, 107, 255, 0.9), rgba(205, 93, 252, 0.9))',
        },
        google: {
            margin: '5px 0px 30px 100px',
            height: '3px',
            borderRadius: '3px',
            width: '350px',
            background:'linear-gradient(to right, rgba(166, 107, 255, 0.9), rgba(205, 93, 252, 0.9))',
        },
        anon: {
            margin: '5px 0px 30px 100px',
            height: '1px',
            width: '330px',
            background:isToggled
            ? 'rgba(255, 255, 255, 0.9)'
            : 'rgba(19, 19, 19, 0.9)',
        }
    };

    const dividerStyles = {
        margin: '40px auto 20px auto',
        height: theme === 'apple' ? '2px' : '1px',
        borderRadius: '3px',
        width: '800px',
        backgroundColor: isToggled
            ? 'rgb(60, 60, 60)'
            : 'rgb(180, 180, 180)',
    };

    const fonts = {
        apple: 'SF-Pro-Text-Thin, sans-serif',
        google: 'ProductSansLight, sans-serif',
        anon: 'unicaone, sans-serif'
    };

    const sectionTitleStyles = {
        margin: '0px 0px 10px 100px',
        fontSize: '26px',
        fontFamily: fonts[theme],
        textTransform: theme === 'anon' ? 'uppercase' : 'none',
        color: isToggled ? 'rgb(235, 235, 235' : 'rgb(80,80,80)',
    };

    const myContributionStyles = {
        margin: '10px 100px 0px 100px',
        fontFamily: fonts[theme],
        textTransform: theme === 'anon' ? 'uppercase' : 'none',
        fontSize: '19px',
        lineHeight: '30px',
        color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(0, 0, 0)' ,
    };

    return(

        <div className='back-page-container'>
            <Link to="/" style={{textDecoration: 'none', marginBottom: '10px', marginRight: '900px'}}>
                <button style={backButtonStyles[theme]}>&#x276E; back</button>
            </Link>

            <div style={projectOnePageStyles[theme]}>

                <div style={pcBannerStyles}>
                    <h2 style={pcBannerTitleStyle}>PocketCipher</h2>
                </div>

                <div className='title-visit-container'>
                    <div className='title-and-divider'>
                        <h2 style={appNameStyles[theme]}>PocketCipher Landing Page</h2>
                        <div style={titleDividerStyles[theme]} />
                    </div>

                    <a href='http://pocket-cipher-static-files.s3-website-us-east-1.amazonaws.com/'>
                        <button style={visitButtonStyles}>visit &#10138;</button>
                    </a>
                </div>

                <p style={myContributionStyles}>
                    This project is a website that I built using the React framework. The purpose
                    of the site was to provide information required for the PocketCipher mobile application
                    to be eligible for App Store deployment, including 
                    PocketCipher's terms of service, privacy policy, and developer contact information.
                </p>

                <div className='project-page-divider' style={dividerStyles} />
                <h2 style={sectionTitleStyles}>My Contribution</h2>

                <div className='contribution-tools-container'>
                    <p style={myContributionStyles}>
                        Since I had already built JOYSTIQ's landing page, this one was a 
                        much simpler task. I used the JOYSTIQ site as a template for this website
                        and was able to re-use many of the same designs and components. Being that PocketCipher
                        was not intended to be a large scale platform, I was not as 
                        concerned about including demo videos or creating a discord server, 
                        I simply needed to build the site to comply with iOS regulations, so I 
                        was able to put it together quickly and launch the site.
                        The app was then approved shortly thereafter and remains on the app store today!
                    </p>

                    <div className='tool-container'>
                        <img className='tool-big-image' src="SkillsLogos/react.png" alt="Skill8" />
                        <img className='tool-big-image' src="SkillsLogos/javascript.png" alt="Skill4" />
                        <img className='tool-big-image' src="SkillsLogos/html.png" alt="Skill10" />
                        <img className='tool-big-image' src="SkillsLogos/css.png" alt="Skill6" />
                        <img className='tool-big-git' src="SkillsLogos/git.png" alt="Skill9" />
                        <img className='tool-big-image' src="SkillsLogos/vscode.webp" alt="Skill9" />
                    </div>
                </div>

                <div className='project-page-divider' style={dividerStyles} />
                <h2 style={sectionTitleStyles}>Quick Look</h2>


                <img src='AppContent/pocketprocess/site-home.png' alt='websitescreenshot' 
                style={{marginTop: '30px', marginLeft: '105px', width: '800px', borderRadius: theme === 'anon' ? '0' : '20px'}}
                />

                <img src='AppContent/pocketprocess/site-contact.png' alt='websitescreenshot' 
                style={{marginTop: '30px', marginLeft: '105px', width: '800px', borderRadius: theme === 'anon' ? '0' : '20px'}}
                />

                <img src='AppContent/pocketprocess/site-tos.png' alt='websitescreenshot' 
                style={{marginTop: '30px', marginLeft: '105px', width: '800px', borderRadius: theme === 'anon' ? '0' : '20px'}}
                />

            </div>
        </div>
    );
};

export default PocketCipherSite;