import '../../styles/ProjectPages/SampleCard.css'

function SampleCard() {
    return(
        
        <div className='example-cards'>

            <div className='card1'>
                <h3 className='label1'>label</h3>
                <p className='label1-2'>secondaryLabel</p>
                <p className='label1-3'>tertiaryLabel</p>
            </div>

            <div className='card2'>
                <h3 className='label2'>label</h3>
                <p className='label2-2'>secondaryLabel</p>
                <p className='label2-3'>tertiaryLabel</p>
            </div>

            <div className='card3'>
                <h3 className='label3'>label</h3>
                <p className='label3-2'>secondaryLabel</p>
                <p className='label3-3'>tertiaryLabel</p>
            </div>


        </div>

    );
}

export default SampleCard;