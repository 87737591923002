import '../styles/Utility/NamePath.css';

const NamePath = ({ isToggled }) => {

    return (
        <svg className="animated-name-path" width="1000" height="200" viewBox="0 0 1000 200">
    <defs>
        <linearGradient id="gradientStroke1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: isToggled ? '#1A73E8' : '#ad7dff', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: isToggled ? '#A8BEFF' : '#378bfa', stopOpacity: 1 }} />
        </linearGradient>
    </defs>
    <path
    className="name-path"
    d="
        M 280 62 
        q -29.4 0 -29.4 25.2
        q 0 29.4 31.92 25.2

        M 300 85 
        q -14 0 -14 14
        q 0 14 14 14
        q 14 0 14 -14
        q 0 -14 -14 -14

        M 320 85
        l 0 28
        l 0 -14
        q 0 -14 14 -14
        q 14 0 14 14
        l 0 14

        M 355 85
        l 0 28
        l 0 -14
        q 0 -14 14 -14
        q 14 0 14 14
        l 0 14

        M 403 85
        q -14 0 -14 14
        q 0 14 14 14
        q 14 0 14 -14
        q 0 -14 -14 -14

        M 425 85
        l 0 28
        l 0 -19.6
        q 0 -9.1 9.1 -9.1
        q 9.1 0 9.1 9.1




        M 500 55
        l -17.5 0
        q -14 0 -14 14
        q 0 14 21 14
        q 10.5 0 12.6 14
        q 0 21 -35 14

        M 522 85
        q -14 0 -14 14
        q 0 14 14 14
        q 14 0 14 -14
        q 0 -14 -14 -14

        M 550 70
        l 0 42
        m -10.5 -24.5
        l 21 0

        M 575 70
        l 0 42
        m -10.5 -24.5
        l 21 0

        M 605 85
        q -14 0 -14 14
        q 0 14 14 14
        q 14 0 14 -14
        q 0 -14 -14 -14

        M 643 83
        l -8.75 0
        q -7 0 -7 7
        q 0 7 10.5 7
        q 5.25 0 6.3 7
        q 0 10.5 -17.5 7

        M 666 85
        q -14 0 -14 14
        q 0 14 14 14
        q 14 0 14 -14
        q 0 -14 -14 -14
        m 14 0
        l 0 28

        M 690 85
        l 0 28
        l 0 -14
        q 0 -14 14 -14
        q 14 0 14 14
        l 0 14

        M 735 70
        l 0 42
        m -10.5 -24.5
        l 21 0

        M 755 85
        l 0 28
        m -1.4 -35
        q 2.1 2.1 2.1 2.1
    "
    fill="transparent"
    stroke="url(#gradientStroke1)"
    strokeWidth="2"
    strokeLinecap="round"
/>

</svg>

    );
};

export default NamePath;
