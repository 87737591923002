import '../../styles/ProjectPages/ProjectOnePage.css';
import '../../styles/ProjectPages/JoystiqSite.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const ProjectOnePage = ({ isToggled, theme }) => {

    useEffect(() => {
        window.scrollTo(0, 0); // Reset scroll position when this component mounts
        }, []);

        //back button
        const backButtonStyles = {
            apple: {
                background: 'none',
                border: 'none',
                color: 'rgb(10, 132, 255)',
                fontSize: '20px',
                cursor: 'pointer'
            },
            google: {
                background: 'none',
                border: 'none',
                color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(255, 255, 255)',
                fontSize: '20px',
                cursor: 'pointer'
            },
            anon: {
                marginTop: '20px',
                fontFamily: 'unicaone, sans-serif',
                position: 'relative',
                zIndex: 2,
                fontSize: '18px',
                cursor: 'pointer',
                backgroundColor: (isToggled ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.8'), 
                color: (isToggled ? 'black' : 'white'),
                border: isToggled ? '2px solid black': '2px solid white',
            }
        };
    
        const projectOnePageStyles = {
            apple: {
                marginTop: '30px',
                display: 'flex',
                flexDirection: 'column',
                width: '1000px',
                minWidth: '900px',
                paddingBottom: '50px',
                borderRadius: '30px',
                backgroundColor: isToggled ? 'rgb(44, 44, 46)' : 'rgb(255, 255, 255)',
                boxShadow: isToggled ? '0px 0px 25px rgba(0, 0, 0, 0.3)' : '0px 0px 25px rgba(0, 0, 0, 0.3)',
                transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
            },
            google: {
                marginTop: '30px',
                display: 'flex',
                flexDirection: 'column',
                width: '1000px',
                minWidth: '900px',
                paddingBottom: '50px',
                borderRadius: '30px',
                backgroundColor: isToggled ? 'rgb(33, 39, 54)' : 'rgb(230, 231, 255)',
                boxShadow: isToggled ? '0px 0px 25px rgba(0, 0, 0, 0.3)' : '0px 0px 25px rgba(0, 0, 0, 0.3)',
                transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
            },
            anon: {
                zIndex: '2',
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                width: '1000px',
                minWidth: '900px',
                paddingBottom: '50px',
                marginBottom: '30px',
                backgroundColor: isToggled ? 'rgba(19, 19, 19, 0.9)' : 'rgba(255, 255, 255, 0.9)',
                border: `2px solid ${isToggled ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.8)'}`,
                boxShadow: isToggled
                    ? '0px 0px 25px rgba(0, 0, 0, 0.9)'
                    : '0px 0px 25px rgba(0, 0, 0, 0.9)',
                transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
            }
            
        };
    
        const appNameStyles = {
            apple: {
                fontFamily:'SF-Pro-Text-Thin, sans-serif',
                fontSize: '30px',
                margin: 0,
                color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(44, 44, 46)',
            },
            google :{
                fontFamily: 'ProductSansLight, sans-serif',
                fontSize: '30px',
                margin: 0,
                color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(44, 44, 46)',
            },
            anon: {
                fontFamily: 'unicaone, sans-serif',
                textTransform: 'uppercase',
                fontSize: '30px',
                margin: 0,
                color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(44, 44, 46)',
            }
            
        };
    
        const appDescriptionStyles = {
            apple: {
                fontFamily:'SF-Pro-Text-Thin, sans-serif',
                fontSize: '15px',
                margin: 0,
                color: isToggled ? 'rgb(180, 180, 180)' : 'rgb(44, 44, 46)',
            },
            google :{
                fontFamily: 'ProductSansLight, sans-serif',
                fontSize: '15px',
                margin: 0,
                color: isToggled ? 'rgb(180, 180, 180)' : 'rgb(44, 44, 46)',
            },
            anon: {
                fontFamily: 'unicaone, sans-serif',
                textTransform: 'uppercase',
                fontSize: '15px',
                margin: 0,
                color: isToggled ? 'rgb(180, 180, 180)' : 'rgb(44, 44, 46)',
            }
        };
    
        const dividerStyles = {
            margin: '40px auto 20px auto',
            height: theme === 'apple' ? '2px' : '1px',
            borderRadius: '3px',
            width: '800px',
            backgroundColor: isToggled
                ? 'rgb(60, 60, 60)'
                : 'rgb(180, 180, 180)',
        };
    
        const verboseDescriptionStyles = {
            apple: {
                flex: 1,
                fontFamily: 'SF-Pro-Text-Thin, sans-serif',
                fontSize: '19px',
                padding: '0px 20px',
                color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(0, 0, 0)',
            },
            google: {
                flex: 1,
                fontFamily: 'ProductSansLight, sans-serif',
                fontSize: '20px',
                paddingLeft: '20px',
                paddingRight: '20px',
                color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(0, 0, 0)',
            },
            anon: {
                flex: 1,
                fontFamily: 'unicaone, sans-serif',
                textTransform: 'uppercase',
                fontSize: '20px',
                paddingLeft: '20px',
                paddingRight: '20px',
                color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(0, 0, 0)',
            }
            
        };
    
        //INFO STYLES ------------
        const appInfoContainer = {
            apple: {
                flex: 1,
                backgroundColor: isToggled ? 'rgba(0,0,0,0.15)' : 'rgba(0,0,0,0.1)',
                borderRadius: '20px',
                height: '280px',
                paddingTop: '12px',
            },
            google: {
                flex: 1,
                height: '280px',
                paddingTop: '12px',
                backgroundColor: isToggled ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.1)',
                borderRadius: '20px'
            },
            anon: {
                flex: 1,
                height: '280px',
                paddingTop: '12px',
                backgroundColor: isToggled ? 'rgba(0,0,0,0.6)' : 'rgba(210,210,210,0.8)',
                border: isToggled ? '1px solid rgba(200,200,200,0.6)' : '1px solid rgba(0,0,0,0.5)',
            }
        };
    
        const infoElementStyles = {
            display: 'flex',
            height: '40px',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '25px',
            paddingRight: '25px'
        };
    
        const fonts = {
            apple: 'SF-Pro-Text-Thin, sans-serif',
            google: 'ProductSansLight, sans-serif',
            anon: 'unicaone, sans-serif'
        };
    
        const infoTextLeft = {
            fontFamily: fonts[theme],
            textTransform: theme === 'anon' ? 'uppercase' : 'none',
            fontSize: '14px',
            color: isToggled ? 'gray' : 'rgb(80,80,80)',
            margin: 0,
        };
    
        const infoTextRight = {
            fontFamily: fonts[theme],
            textTransform: theme === 'anon' ? 'uppercase' : 'none',
            fontSize: '15px',
            color: isToggled ? 'white' : 'black',
            margin: 0,
        };
    
        const infoDividerStyles = {
            margin: '0px auto 0px auto',
            height: theme === 'anon' ? '1px' : '2px',
            borderRadius: '3px',
            width: '250px',
            backgroundColor: isToggled
                ? 'rgb(60, 60, 60)'
                : 'rgb(180, 180, 180)',
        };
        //END INFO STYLES ------------
    
        const sectionTitleStyles = {
            margin: '0px 0px 10px 100px',
            fontSize: '26px',
            fontFamily: fonts[theme],
            textTransform: theme === 'anon' ? 'uppercase' : 'none',
            color: isToggled ? 'rgb(235, 235, 235' : 'rgb(80,80,80)',
        };
    
        const sectionSubtitleStyles = {
            margin: '0px 0px 30px 100px',
            fontFamily: fonts[theme],
            textTransform: theme === 'anon' ? 'uppercase' : 'none',
            fontSize: '20px',
            color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(110,110,110)',
        };
    
        const myContributionStyles = {
            margin: '10px 100px 0px 100px',
            fontFamily: fonts[theme],
            textTransform: theme === 'anon' ? 'uppercase' : 'none',
            fontSize: '19px',
            lineHeight: '30px',
            color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(0, 0, 0)' ,
        };
    
    return(

        <div className='back-page-container'>

            <Link to="/" style={{textDecoration: 'none', marginBottom: '10px', marginRight: '900px'}}>
                <button style={backButtonStyles[theme]}>&#x276E; back</button>
            </Link>

            <div style={projectOnePageStyles[theme]}>
                <div className='icon-name-container'> 
                    <img src='AppContent/PocketCipherLogo.png' className='pc-page-logo' alt='appicon' />
                    
                    <div className='name-description-container'>
                        <h2 style={appNameStyles[theme]}>PocketCipher</h2>
                        <h3 style={appDescriptionStyles[theme]}>Mobile Decryption tool</h3>
                        <div className='free-button' style={{borderRadius: theme === 'anon' ? '0px' : '15px'}}>
                            <p className='free-title'>Free</p>
                        </div>

                    </div> 
                </div>

                <div className='project-page-divider' style={dividerStyles} />

                <h2 style={sectionTitleStyles}>Information</h2>

                <div className='screenshot-information-container'>
                    <img src='AppContent/pocketprocess/latestscreenshot.png' className='pc-app-screenshot' alt='appscreenshot' />

                    <div style={verboseDescriptionStyles[theme]}>
                        PocketCipher is an iOS application that can be used to encrypt or
                        decrypt user input. Currently,
                        users have access to encryption & decryption using the Caeser,
                        Atbash, and ROT13 ciphers as well as hex, binary, and base64 encrption methods. 
                        The app is only in its second version 
                        so users can expect more to be added in later iterations!
                    </div>

                    <div style={appInfoContainer[theme]}>
                    
                        <div style={infoElementStyles}>
                            <p style={infoTextLeft}>Seller</p>
                            <p style={infoTextRight}>JOYSTIQ LLC</p>
                        </div>
                        <div style={infoDividerStyles}/>

                        <div style={infoElementStyles}>
                            <p style={infoTextLeft}>Category</p>
                            <p style={infoTextRight}>Utlilites</p>
                        </div>
                        <div style={infoDividerStyles}/>

                        <div style={infoElementStyles}>
                            <p style={infoTextLeft}>Compatability</p>
                            <p style={infoTextRight}>iOS 16+</p>
                        </div>
                        <div style={infoDividerStyles}/>

                        <div style={infoElementStyles}>
                            <p style={infoTextLeft}>Languages</p>
                            <p style={infoTextRight}>English</p>
                        </div>
                        <div style={infoDividerStyles}/>

                        <div style={infoElementStyles}>
                            <p style={infoTextLeft}>Age Rating</p>
                            <p style={infoTextRight}>4+</p>
                        </div>
                        <div style={infoDividerStyles}/>

                        <div style={infoElementStyles}>
                            <p style={infoTextLeft}>In-App Purchases</p>
                            <p style={infoTextRight}>No</p>
                        </div>


                    </div>

                </div>

                <div className='project-page-divider' style={dividerStyles} />

                <h2 style={sectionTitleStyles}>My Contribution</h2>
                
                <div className='contribution-tools-container'>
                    <p style={myContributionStyles}>
                        For this project, I began by brainstorming potential logos that would fit nicely
                        on an app icon. I looked into popular terminology and images used in cryptography
                        and the most prominent were locks and keys. I decided to go with a key design 
                        and began coming up with concepts using GIMP. After several iterations, I settled 
                        with the current design. I then began to design mock ups of the user interface with Figma.
                        After completing the UI Design, I developed the user interface with the SwiftUI
                        framework. To be app store eligible, iOS apps need websites with legal and contact 
                        information, so I quickly built and launched a website to meet those requirements.
                        PocketCipher is available now on the app store and I plan to continuously iterate
                        on the design and add more encryption and decryption methods!
                    </p>

                    <div className='tool-container'>
                        <img className='tool-big-gimp' style={{marginLeft: '-15px'}} src="SkillsLogos/gimp.png" alt="Skill9" />
                        <img className='tool-big-figma' src="SkillsLogos/figma.png" alt="Skill8" />
                        <img className='tool-big-image' src="SkillsLogos/swift.png" alt="Skill4" />
                        <img className='tool-big-image' src="SkillsLogos/xcode.png" alt="Skill6" />
                        <img className='tool-big-git' src="SkillsLogos/git.png" alt="Skill10" />
                        <img className='tool-big-image' src="SkillsLogos/react.png" alt="Skill8" />
                    </div>
                </div>

                <div className='project-page-divider' style={dividerStyles} />

                <h2 style={sectionTitleStyles}>Logo Design with GIMP</h2>

                <div className='design-iterations'>
                    <img src='AppContent/pocketprocess/progress1.png' className='iteration-image' alt='gimpimage' />
                    <p style={{margin: '10px', color: isToggled? 'rgb(220,220,220)' : 'rgb(66,66,46)', fontSize: '40px'}}> &#8595; </p>
                    <img src='AppContent/pocketprocess/progress2.png' className='iteration-image' alt='gimpimage' />
                    <p style={{margin: '10px', color: isToggled? 'rgb(220,220,220)' : 'rgb(66,66,46)', fontSize: '40px'}}> &#8595; </p>
                    <img src='AppContent/pocketprocess/progress3.png' className='iteration-image' alt='gimpimage' />
                </div>

                <div className='project-page-divider' style={dividerStyles} />

                <h2 style={sectionTitleStyles}>App Design and Development</h2>
                <h3 style={{...sectionSubtitleStyles, margin: '0px 50px 30px 100px', paddingRight: '50px'}}>Translating Figma User Interface Design to Swift code</h3>

                <div className='development-iterations'>
                    <img src='AppContent/pocketprocess/figmascreenshot.png' className='development-image' alt='figmaimage' />
                    <p style={{margin: '10px', color: isToggled? 'rgb(220,220,220)' : 'rgb(66,66,46)', fontSize: '40px'}}> &#8595; </p>
                    <img src='AppContent/pocketprocess/xcodescreenshot.png' className='development-image' alt='xcodeimage' />
                </div>

            </div>

        </div>
    );
};

export default ProjectOnePage;