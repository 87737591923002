import { Link } from 'react-router-dom';

const ProjectCardTwo = ({ theme, isToggled }) => {

    const projectTwoContainer = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '400px',
        minWidth: '380px',
        height: '600px',
        ...(isToggled
            ? {
                background: 'linear-gradient(to bottom, rgba(27, 75, 72, 0.7), rgba(7, 43, 33, 0.7))'
              }
            : {
                backgroundColor: 'rgb(224, 254, 233)'
              }),

        borderRadius: '30px',
        border: isToggled ? '1px solid rgba(128, 128, 128, 0.2)' : '1px solid rgba(0, 0, 0, 0.2)',
        boxShadow: isToggled ? '0 0 20px 0 rgba(15, 135, 57, 0.2)' : '0 0 20px 0 rgba(0, 197, 0, 0.2)',
        
    };

    const logoStylesThemed = {
        apple: {
            width: '100px',
            height: '100px',
            borderRadius: '25px',
            boxShadow: isToggled ? '0 0 10px 0 rgba(15, 135, 57, 0.2)' : '0 0 10px 0 rgba(15, 135, 57, 0.5)',
            border: isToggled ? '1px solid rgba(0,0,0,0.3)' : 'none',
            marginTop: '30px'
        },
        google: {
            width: '100px',
            height: '100px',
            borderRadius: '25px',
            boxShadow: isToggled ? '0 0 20px 0 rgba(0, 0, 0, 0.1)' : '0 0 20px 0 rgba(0, 0, 0, 0.1)',
            border: isToggled ? '1px solid rgba(0,0,0,0.3)' : '1px solid rgba(0,0,0,0.3)',
            marginTop: '30px'
        }
      };

    const titleStylesThemed = {
        apple: {
            fontFamily: 'SF-Pro-Display-Thin, sans-serif',
            fontSize: '35px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'black',
            marginTop: '20px',
            marginBottom: '0px'
        },
        google: {
            fontFamily: 'ProductSansRegular, sans-serif',
            fontSize: '35px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'black',
            marginTop: '20px',
            marginBottom: '0px'
        }
    };

    const subtitleStylesThemed = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            color: isToggled ? 'rgb(190, 190, 190)' : 'rgb(92, 92, 92)',
            marginTop: '5px',
            marginBottom: '20px'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '20px',
            color: isToggled ? 'rgb(190, 190, 190)' : 'rgb(92, 92, 92)',
            marginTop: '5px',
            marginBottom: '20px'
        }
    };

    const projectDescriptionStyles = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'rgb(0, 0, 0)',
            marginTop: '10px',
            paddingLeft: '23px',
            paddingRight: '23px'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '22px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'rgb(0, 0, 0)',
            marginTop: '10px',
            paddingLeft: '20px',
            paddingRight: '20px'
        }
    };

    const readMoreStyles = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            cursor: 'pointer',
            marginTop: '10px',
            marginBottom: '50px',
            color: 'white',
            backgroundColor: isToggled ? 'rgb(34, 34, 36)' : 'green',
            padding: '8px 12px',
            borderRadius: '20px',
            border: '1px solid rgba(180,180,180,0.3)'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '20px',
            cursor: 'pointer',
            marginTop: '5px',
            marginBottom: '45px',
            color: 'white',
            backgroundColor: isToggled ? 'rgb(34, 34, 36)' : 'green',
            padding: '4px 12px',
            borderRadius: '20px',
            border: '1px solid rgba(180,180,180,0.3)'
        }
    };

    const toolStyles = {
        list: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
        },
        image: {
            width: '30px',
            height: '30px'
        },
        gimp: {
            width: '40px',
            height: '30px',
        },
        figma: {
            width: '35px',
            height: '25px',
            marginRight: '-5px'
        },
        blender: {
            width: '33px',
            height: '30px'
        },
        git: {
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            padding: '1px',
            backgroundColor: isToggled ? 'gray' : 'lightgray'
        }
    };

    return(
        <div style={projectTwoContainer}>

            <img src='AppContent/JoystiqLogo.png' style={logoStylesThemed[theme]} alt='joystiqlogo' />

            <h2 style={titleStylesThemed[theme]}>JOYSTIQ</h2>

            <h2 style={subtitleStylesThemed[theme]}>iOS Application</h2>

            <p style={projectDescriptionStyles[theme]}>
            Gamified social media platform that allows users to
            post and engage with video and image content, follow friends, and express themselves with 
            profiles where they can customize their own 3D avatar.
            </p>

            <Link to="/joystiqapp" style={{textDecoration: 'none'}}>
                <button style={readMoreStyles[theme]}>read more</button>
            </Link>

            <div style={toolStyles['list']}>
                <img style={toolStyles['image']} src="SkillsLogos/balsamiq.png" alt="Skill8" />
                <img style={toolStyles['image']} src="SkillsLogos/swift.png" alt="Skill4" />
                <img style={toolStyles['image']} src="SkillsLogos/xcode.png" alt="Skill10" />
                <img style={toolStyles['git']} src="SkillsLogos/git.png" alt="Skill6" />
                <img style={toolStyles['gimp']} src="SkillsLogos/gimp.png" alt="Skill9" />
                <img style={toolStyles['blender']} src="SkillsLogos/blender.png" alt="Skill9" />
            </div>

        </div>
    );
};

export default ProjectCardTwo;