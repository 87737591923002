import { Link } from 'react-router-dom';

const JoystiqAppAnonCard = ({ isToggled }) => {

    //conditional styles
    const projectTwoContainer = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '400px',
        minWidth: '380px',
        height: '600px',
        ...(isToggled
            ? {
                background: 'linear-gradient(to bottom, rgba(27, 75, 72, 0.95), rgba(7, 43, 33, 0.95))'
              }
            : {
                backgroundColor: 'rgba(224, 254, 233,0.9)'
              }),

        border: isToggled ? '1px solid rgba(255, 255, 255, 0.3)' : '1px solid rgba(0, 0, 0, 0.3)',
        boxShadow: isToggled
            ? '0 0 30px 0 rgba(255, 255, 255, 0.2)'
            : '0 0 30px 0 rgba(0, 0, 0, 0.2)',
        
    };

    const logoStyles = {
        width: '100px',
        height: '100px',
        boxShadow: isToggled ? '0 0 30px 0 rgba(15, 135, 57, 0.4)' : '0 0 30px 0 rgba(15, 135, 57, 0.5)',
        border: isToggled ? '1px solid rgba(255, 255, 255, 0.1)' : '1px solid rgba(0, 0, 0, 0.3)',
        marginTop: '30px',
      };
      

    const titleStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '35px',
        color: isToggled ? 'white' : 'black',
        marginTop: '20px',
        marginBottom: '0px',
    };
    
    const subtitleStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        color: isToggled ? 'rgb(200,200,200)' : 'rgb(80,80,80)',
        marginTop: '5px',
        marginBottom: '20px',
    };

    const descriptionStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        color: isToggled ? 'rgb(240,240,240)' : 'rgb(20,20,20)',
        marginTop: '10px',
        marginBottom: '20px',
        paddingLeft: '23px',
        paddingRight: '23px',
    };

    const readMoreStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        cursor: 'pointer',
        marginTop: '5px',
        marginBottom: '50px',
        color: isToggled ? 'white' : 'white',
        backgroundColor: isToggled ? 'rgb(44, 44, 46)' : 'green' ,
        padding: '8px 12px',
        border: isToggled ? '1px solid rgba(180,180,180,0.3)' : '1px solid rgba(20,20,20,0.3)' 
    };

    const toolStyles = {
        list: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
        },
        image: {
            width: '30px',
            height: '30px'
        },
        gimp: {
            width: '40px',
            height: '30px',
        },
        figma: {
            width: '35px',
            height: '25px',
            marginRight: '-5px'
        },
        blender: {
            width: '33px',
            height: '30px'
        },
        git: {
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            padding: '1px',
            backgroundColor: isToggled ? 'gray' : 'rgb(224, 254, 233)'
        }
    };

    return(
        <div style={projectTwoContainer}>

            <img src='AppContent/JoystiqLogo.png' style={logoStyles} alt='joystiqlogo' />

            <h2 style={titleStyle}>JOYSTIQ</h2>

            <h2 style={subtitleStyle}>IOS APPLICATION</h2>

            <p style={descriptionStyle}>
            GAMIFIED SOCIAL MEDIA PLATFORM THAT ALLOWS USERS TO POST AND ENGAGE WITH
            VIDEO AND IMAGE CONTENT, FOLLOW FRIENDS, AND EXPRESS THEMSELVES WITH
            PROFILES WHERE THEY CAN CUSTOMIZE THEIR OWN 3D AVATAR.
            </p>

            <Link to="/joystiqapp" style={{textDecoration: 'none'}}>
                <button style={readMoreStyle}>READ MORE</button>
            </Link>

            <div style={toolStyles['list']}>
                <img style={toolStyles['image']} src="SkillsLogos/balsamiq.png" alt="Skill8" />
                <img style={toolStyles['image']} src="SkillsLogos/swift.png" alt="Skill4" />
                <img style={toolStyles['image']} src="SkillsLogos/xcode.png" alt="Skill10" />
                <img style={toolStyles['git']} src="SkillsLogos/git.png" alt="Skill6" />
                <img style={toolStyles['gimp']} src="SkillsLogos/gimp.png" alt="Skill9" />
                <img style={toolStyles['blender']} src="SkillsLogos/blender.png" alt="Skill9" />
            </div>

        </div>
    );
};

export default JoystiqAppAnonCard;