import '../../../styles/HomePageCards/AnonCards/AnonProfileCard.css';

const JobCardAnon = ({ isToggled, cardContent }) => {

    const titleDateContainerStyles = {
        height: '20px',
        display: 'flex',
        paddingTop: '10px',
        paddingLeft: '20px',
        paddingBottom: '15px',
        alignItems: 'baseline',
    }

    const jobTitleStyles = {
        textTransform: 'uppercase',
        fontFamily: 'unicaone, sans-serif',
        fontSize: '30px',
        margin: 0,
        color: isToggled ? 'rgb(255, 255, 255)' : 'rgb(44, 44, 46)',
    };

    const jobInfoStyles = {
        textTransform: 'uppercase',
        fontFamily: 'unicaone, sans-serif',
        fontSize: '22px',
        margin: 0,
        color: isToggled
            ? 'rgb(255, 255, 255)'
            : 'rgb(44, 44, 46)',
    };

    const infoDateContainerStyles = {
        height: '20px',
        display: 'flex',
        paddingTop: '25px',
        paddingLeft: '20px',
        paddingBottom: '15px',
        alignItems: 'baseline',
    }

    const dateStyles = {
        textTransform: 'uppercase',
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        margin: 0,
        marginLeft: '10px',
        color: isToggled
            ? 'rgb(255, 255, 255)'
            : 'rgb(44, 44, 46)',
    };

    const listItemStyles = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '18px',
        textTransform: 'uppercase',
        color: isToggled ? 'white' : 'black',
        marginBottom: '7px'
    };

    return(

        <div className={isToggled ? "borderedDivDark" : "borderedDivLight"}>
            <div style={titleDateContainerStyles}>
                <h2 style={jobTitleStyles}>{cardContent[0]}</h2>
                <h3 style={dateStyles}>{cardContent[1]}</h3>
            </div>

            <div style={infoDateContainerStyles}>
                <h2 style={jobInfoStyles}>{cardContent[2]}</h2>
                <h3 style={dateStyles}>{cardContent[3]}</h3>
            </div>

            <ul style={{marginTop: '20px', textAlign: 'start'}}>
                <li style={listItemStyles}>{cardContent[4]}</li>
                <li style={listItemStyles}>{cardContent[5]}</li>
                <li style={listItemStyles}>{cardContent[6]}</li>
                <li style={listItemStyles}>{cardContent[7]}</li>
                {cardContent[8] && <li style={listItemStyles}>{cardContent[8]}</li>}
            </ul>

        </div>

    );
};

export default JobCardAnon;