import '../../styles/HomePageCards/SkillsCard.css';
import CardBorderSVG from '../../utility/CardBorderSVG';

const SkillsCard = ({ theme, isToggled }) => {

     //unchanging styles
    const skillsCardContainerStyles = {
        position: 'relative',
        margin: '20px',
        textAlign: 'center',
        width: '500px',
        height: '300px',
        borderRadius: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
    };

    const skillsContainerStylesThemed = {
        apple: {
            ...skillsCardContainerStyles,
            backgroundColor: isToggled
            ? 'rgb(44, 44, 46)'
            : 'rgb(255, 255, 255)',
            boxShadow: isToggled
                ? '0px 0px 15px rgba(0, 0, 0, 0.3)'
                : '0px 0px 15px rgba(0, 0, 0, 0.3)',
        },
        google: {
            ...skillsCardContainerStyles,
            borderRadius: '30px',
            background: isToggled ? 'radial-gradient(circle, rgb(40, 49, 66), rgb(27, 33, 45))' 
            : 'radial-gradient(circle, rgb(212, 214, 250), rgb(230, 231, 255))',
                
        },
        anon: {
        }
    };

    const titleStylesThemed = {
        apple: {
            fontFamily: 'SF-Pro-Display-Ultralight, sans-serif',
            fontSize: '46px',
            marginTop: 0,
            marginBottom: 0,
            paddingTop: '15px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'black',

        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '46px',
            marginTop: 0,
            marginBottom: 0,
            paddingTop: '15px',
            color: isToggled ? 'rgb(114, 172, 250)' : '#7940ff',
        }  
    };

    const descriptionStylesThemed = {
        apple :{
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            marginTop: '10px',
            marginBottom: '0px',
            color: isToggled ? 'rgb(218, 218, 218)' : 'rgb(0,0,0)'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '20px',
            marginTop: '10px',
            marginBottom: '0px',
            color: isToggled ? 'rgb(218,218,218)' : 'rgb(0,0,0)'
        },
    };

    const skillListStyles = {
        width: '85%',
        overflowX: 'auto',
        overflowY: 'hidden',
        zIndex: '3'
    };
      
    const skillsContainerStyles = {
        display: 'flex',
        alignItems: 'center',
        gap: '20px', // Adjust the space between images
        paddingTop: '30px',
        paddingBottom: '30px',
    };


    return(
        <div style={skillsContainerStylesThemed[theme]}>
            {theme === 'google' ? <CardBorderSVG isToggled={isToggled} cardType={'skills'} /> : null}

            <h3 style={titleStylesThemed[theme]}>My Skills</h3>
            <p style={descriptionStylesThemed[theme]}>Below are some things I am skilled at. </p>

            <div style={skillListStyles} className={isToggled ? 'skills-list-dark' : 'skills-list-light'}>
                <div style={skillsContainerStyles}>
                    <img className='generic-image' src="SkillsLogos/javascript.png" alt="Skill1" />
                    <img className='generic-image' src="SkillsLogos/html.png" alt="Skill2" />
                    <img className='generic-image' src="SkillsLogos/css.png" alt="Skill3" />
                    <img className='generic-image' src="SkillsLogos/swift.png" alt="Skill4" />
                    <img className='generic-image' style={{scale: '1.2'}} 
                    src="SkillsLogos/java.png" alt="Skill5" />
                    <img className='sql-image' src="SkillsLogos/sql.png" alt="Skill5" />
                    <img className='generic-image' src="SkillsLogos/react.png" alt="Skill5" />
                    <img className='generic-image git-image' src="SkillsLogos/git.png" alt="Skill6" />
                    <img className='generic-image' src="SkillsLogos/python.png" alt="Skill7" />
                    <img className='figma-image' src="SkillsLogos/figma.png" alt="Skill75" />
                    <img className='generic-image' src="SkillsLogos/balsamiq.png" alt="Skill8" />
                    <img className='gimp-image' src="SkillsLogos/gimp.png" alt="Skill9" />
                    <img className='generic-image' src="SkillsLogos/xcode.png" alt="Skill10" />
                    <img className='vscode-image' src="SkillsLogos/vscode.webp" alt="Skill11" />

                </div>
            </div>

        </div>
    );
};

export default SkillsCard;