import { Link } from 'react-router-dom';
import CardBorderSVG from '../../utility/CardBorderSVG';

const ProifleCard = ({ isToggled, theme }) => {

    //unchanging styles
    const profileContainerStyles = {
        marginTop: '20px',
        textAlign: 'center',
        width: '600px',
        height: '300px',
        borderRadius: '30px',
        transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
        position: 'relative'
    };

    const profileContainerStylesThemed = {
        apple: {
            ...profileContainerStyles,
            backgroundColor: isToggled ? 'rgb(44, 44, 46)' : 'rgb(255, 255, 255)',
            boxShadow: isToggled ? '0px 0px 15px rgba(0, 0, 0, 0.3)' : '0px 0px 15px rgba(0, 0, 0, 0.3)',
        },
        google: {
            ...profileContainerStyles,
            background: isToggled ? 'radial-gradient(circle, rgb(40, 49, 66), rgb(27, 33, 45))' 
            : 'radial-gradient(circle, rgb(212, 214, 250), rgb(230, 231, 255))',
        }
    };

    

    const profileNameStylesThemed = {
        apple: {
            fontFamily: "SF-Pro-Display-Thin, sans-serif",
            fontSize: "46px",
            backgroundImage: isToggled ? 'linear-gradient(45deg, #7539b0, rgb(254, 97, 97))' : "linear-gradient(200deg, #eaff00, rgb(255, 0, 0))",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
            marginTop: "0px",
            paddingTop: "20px",
            marginBottom: "5px"
        },
        google: {
            fontFamily: "ProductSansLight, sans-serif",
            fontSize: "46px",
            backgroundImage: isToggled ? 'linear-gradient(30deg, #1A73E8 9.29%, #40A2FF 48.23%, #A8BEFF 82.56%)' : "linear-gradient(10deg, #1A73E8 9.29%, #7940ff 48.23%, #A8BEFF 82.56%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
            marginTop: "0px",
            paddingTop: "20px",
            marginBottom: "5px"
        },
        anon: {

        }
    };

    const jobTitleStylesThemed = {
        apple: {
            fontFamily: 'SF-Pro-Text-Ultralight, sans-serif',
            fontSize: '24px',
            marginTop: 0,
            marginBottom: '20px',
            color: isToggled ? 'rgb(129,129,129)' : 'rgb(47,47,47)',
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '24px',
            marginTop: 0,
            marginBottom: '20px',
            color: isToggled ? 'rgb(147, 157, 182)' : 'rgb(117, 117, 117)',
        },
        anon: {

        }
        
    };

    const descriptionStylesThemed = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            margin: 0,
            paddingLeft: '30px',
            paddingRight:' 30px',
            color: isToggled ? 'rgb(218,218,218)' : 'rgb(0,0,0)',
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '22px',
            margin: 0,
            paddingLeft: '30px',
            paddingRight: '30px',
            color: isToggled ? 'rgb(218,218,218)' : 'rgb(0,0,0)',
        },
        anon: {

        }
        
    };

    const readMoreStylesThemed = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            cursor: 'pointer',
            marginTop: '15px',
            marginBottom: '50px',
            color: isToggled ? 'white' : 'white',
            backgroundColor: isToggled ? 'rgba(85, 85, 85)' : 'rgb(124, 124, 126)' ,
            padding: '8px 12px',
            borderRadius: '20px',
            border: isToggled ? '1px solid rgba(180,180,180,0.3)' : '1px solid rgba(20,20,20,0.3)'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '20px',
            cursor: 'pointer',
            marginTop: '15px',
            marginBottom: '50px',
            color: isToggled ? 'white' : 'white',
            backgroundColor: isToggled ? 'rgba(85, 85, 85)' : 'rgb(124, 124, 126)' ,
            padding: '4px 12px',
            borderRadius: '20px',
            border: isToggled ? '1px solid rgba(180,180,180,0.3)' : '1px solid rgba(20,20,20,0.3)',
            zIndex: 3, // Ensure button has a higher z-index than SVG
            position: 'relative'
        },
        anon: {

        }
    };

    
    return(
        <div style={profileContainerStylesThemed[theme]}>
            
            {theme === 'google' ? <CardBorderSVG isToggled={isToggled} cardType={'profile'} /> : null}


            <h2 style={profileNameStylesThemed[theme]}>Connor Sottosanti</h2>
            <h3 style={jobTitleStylesThemed[theme]}>Personal Portfolio</h3>
            <p style={descriptionStylesThemed[theme]}>
            Thanks for checking out my website! 
            I made it with React to showcase some of my recent projects.
            To learn more about how I built this site, click below.
            </p>

            <Link to="/project-portfolio" style={{textDecoration: 'none'}}>
              <button style={readMoreStylesThemed[theme]}>read more</button>
            </Link>
        </div>
    );

};

export default ProifleCard;