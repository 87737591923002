import React, { useState } from "react";
import "../styles/Utility/ThemeToggler.css";

const ThemeToggler = ({ isToggled, onThemeChange, theme }) => {
  const [switchPosition, setSwitchPosition] = useState("center");
  const [animation, setAnimation] = useState(null);

  const getSwitchAnimation = (value) => {
    let newAnimation = null;
    let selectedTheme = "google"; // Default theme is "google"

    if (value === "center" && switchPosition === "left") {
      newAnimation = "left-to-center";
      selectedTheme = "google";
    } else if (value === "right" && switchPosition === "center") {
      newAnimation = "center-to-right";
      selectedTheme = "anon";
    } else if (value === "center" && switchPosition === "right") {
      newAnimation = "right-to-center";
      selectedTheme = "google";
    } else if (value === "left" && switchPosition === "center") {
      newAnimation = "center-to-left";
      selectedTheme = "apple";
    } else if (value === "right" && switchPosition === "left") {
      newAnimation = "left-to-right";
      selectedTheme = "anon";
    } else if (value === "left" && switchPosition === "right") {
      newAnimation = "right-to-left";
      selectedTheme = "apple";
    }

    onThemeChange(value);
    setSwitchPosition(value);
    setAnimation(newAnimation);
    onThemeChange(selectedTheme);
  };

  const containerStyles = {
    apple: {
      marginRight: '20px',
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '250px',
      height: '50px',
      borderRadius: '100px',
      backgroundColor: isToggled ? '#4d4d4d' : 'rgb(247, 247, 247)',
      position: 'relative',
      boxShadow: isToggled ? '1px 1px 5px 1px rgba(0, 0, 0, 0.1)' : '1px 1px 5px 1px rgba(0, 0, 0, 0.1)',
    },
    google: {
      marginRight: '20px',
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '250px',
      height: '50px',
      borderRadius: '100px',
      backgroundColor: isToggled ? 'rgb(86, 98, 133)' : '#c0c3fc',
      position: 'relative',
      boxShadow: isToggled ? '1px 1px 10px 1px rgba(0, 0, 0, 0.25)' : '0px 0px 3px 0px rgba(0, 0, 0, 0.25)',
    },
    anon: {
      marginRight: '20px',
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '250px',
      height: '50px',
      backgroundColor: isToggled ?  'rgb(120, 120, 120, 0.8)' : 'rgba(244, 244, 244, 0.8)',
      position: 'relative',
      boxShadow: isToggled ? '0px 0px 5px rgba(238, 238, 238, 0.6)' : '0px 0px 10px rgba(20,20,20,0.4)',
    }
  };

  const switchColors = {
    apple: {
      backgroundColor: isToggled ? 'white' : 'rgb(200,200,200)'
    },
    google: {
      backgroundColor: isToggled ? 'rgb(179, 198, 252)' : 'rgb(129, 147, 199)'
    },
    anon: {
      backgroundColor: isToggled ? 'rgb(200,200,200)' : 'rgb(220,220,220)',
      border: isToggled ? '2px outset gray' : '2px outset gray',
      borderRadius: '0px'
    }
  };

  return (
    <div style={containerStyles[theme]}>
      <div
        className={`switch ${animation} ${switchPosition}-position`}
        style={switchColors[theme]}
      ></div>
      <input
        onChange={(e) => getSwitchAnimation(e.target.value)}
        name="map-switch"
        id="left"
        type="radio"
        value="left"
      />
      <label
        className={`left-label ${
          switchPosition === "left" ? "black-font" : ""
        }`}
        htmlFor="left"
      >
        <img src="misc/apple.png" style={{ width: "35px", height: "35px" }} alt="left" />
      </label>

      <input
        defaultChecked
        onChange={(e) => getSwitchAnimation(e.target.value)}
        name="map-switch"
        id="center"
        type="radio"
        value="center"
      />
      <label
        className={`center-label ${
          switchPosition === "center" ? "black-font" : ""
        }`}
        htmlFor="center"
      >
        <img src="misc/gemini.png" style={{ width: "35px", height: "35px" }} alt="center" />
      </label>

      <input
        onChange={(e) => getSwitchAnimation(e.target.value)}
        name="map-switch"
        id="right"
        type="radio"
        value="right"
      />
      <label
        className={`right-label ${
          switchPosition === "right" ? "black-font" : ""
        }`}
        htmlFor="right"
      >
        <img src="misc/eyecon.png" style={{ width: "35px", height: "35px" }} alt="right" />
      </label>
    </div>
  );
};

export default ThemeToggler;
