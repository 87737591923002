import React, { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const ParticlesBackground = ({ id, style, isToggled  }) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      autoPlay: true,

      fullScreen: {
        enable: false,
        zIndex: 0
      },
      detectRetina: true,
      fpsLimit: 60,
      interactivity: {
        detectsOn: "window",
        events: {
          onClick: {
            enable: false,
          },
          onHover: {
            enable: false,
          },
          resize: {
            delay: 0.5,
            enable: true
          }
        },
        modes: {
          grab: {
            distance: 100,
            links: {
              blink: false,
              consent: false,
              opacity: 1
            }
          }
        }
      },
      particles: {
        links: {
          enable: true,
          distance: 160,
          color: isToggled ? "#d3d3d3" : "#828282",
          opacity: 0.4,
          width: 1
        },
        move: {
          enable: true,
          speed: 1.2,
          direction: "none",
          random: false,
          straight: false,
          outModes: {
            default: "out",
          }
        },
        number: {
          value: 160,
          density: {
            enable: true,
            area: 800
          }
        },
        shape: {
          type: "circle"
        },
        size: {
          value: 1,
          random: {
            enable: true,
            minimumValue: 1
          },
          animation: {
            enable: false,
          }
        },
        opacity: {
          value: 0.2,
          random: {
            enable: true,
            minimumValue: 0.1
          },
          animation: {
            enable: false,
          }
        }
      },
      pauseOnBlur: true,
      pauseOnOutsideViewport: true,
      zLayers: 100,
      motion: {
        disable: false,
        reduce: {
          factor: 4,
          value: true
        }
      }
    }),
    [isToggled]
  );

  return (
    <Particles
      id={id}
      options={options}
      init={initParticlesEngine}
      loaded={particlesLoaded}
      style={style}
    />
  );
};

export default ParticlesBackground;