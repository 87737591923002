const Footer = ({theme, isToggled}) => {

    const containerStyles = {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '70px',
        height: '80px',
        zIndex: 1
    };

    const containerStylesThemed = {
        apple: {
            ...containerStyles,
            backgroundColor: isToggled ? 'rgba(13, 13, 13, 0.7)' : 'rgba(187, 187, 187, 0.6)',
            borderTop: '1px solid rgba(0, 0, 0, 0.2)',
            boxShadow: isToggled ? '0px -10px 20px rgba(63, 63, 63, 0.2)' : '0px -10px 20px rgba(0, 0, 0, 0.15)'
        },
        google: {
            ...containerStyles,
            backgroundColor: isToggled ? 'rgba(13, 13, 13, 0.4)' : 'rgba(255, 255, 255, 0.2)',
            boxShadow: isToggled ? '0px -10px 20px rgba(63, 63, 63, 0.2)' : '0px -10px 20px rgba(0, 0, 0, 0.1)'
        },
        anon: {
            ...containerStyles,
            backgroundColor: isToggled ? 'rgba(13, 13, 13, 0.9)' : 'rgba(187, 187, 187, 0.9)',
            borderTop: '1px solid rgba(0, 0, 0, 0.2)',
            boxShadow: isToggled ? '0px -10px 20px rgba(63, 63, 63, 0.2)' : '0px -10px 20px rgba(0, 0, 0, 0.15)'
        }
    };

    const footerInfoStyles = {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        marginTop: '10px'
    }
  
    const footerTextStyles = {
        marginTop: '0',
        color: isToggled ? 'rgb(156, 156, 156)' : 'rgb(0, 0, 0)',
        fontFamily: "ProductSansLight, sans-serif",
        fontSize: '16px'
      };
    
    const dividerStyles = {
        color: isToggled? 'rgb(160,160,160)' : 'rgb(136,136,136)', 
        fontSize: '20px',
        marginTop: '-5px'
    }
  
    const linkedinLogoStyles = {
        width: '90px',
        height: '30px',
        borderRadius: '5px',
        marginBottom: '13px'
    };

    return (
        <div style={containerStylesThemed[theme]}>
            <div style={footerInfoStyles}>
              <p style={footerTextStyles}>connorsottosanti@gmail.com</p>
              <p style={dividerStyles}> &#124; </p> 
              <p style={footerTextStyles}>908&#8209;472&#8209;3258</p>
              <p style={dividerStyles}> &#124; </p> 
              <a href='https://www.linkedin.com/in/connor-sottosanti-3725201b1'>
                <img src="LinkedInLogo.png" alt="li-logo" style={linkedinLogoStyles} />
              </a>
            </div>
          </div>
    );
};

export default Footer;