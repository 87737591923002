import { Link } from 'react-router-dom';

const ProjectCardThree = ({ theme, isToggled }) => {

    //conditional styles
    const projectThreeContainer = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '400px',
        minWidth: '380px',
        height: '600px',
        ...(isToggled
            ? {
                background: 'linear-gradient(to bottom, rgba(26, 77, 72, 0.7), rgba(16, 98, 75, 0.7))'
              }
            : {
                backgroundColor: '#c6f5e2'
              }),

        borderRadius: '30px',
        border: isToggled ? '1px solid rgba(128, 128, 128, 0.2)' : '1px solid rgba(0, 0, 0, 0.2)',
        boxShadow: isToggled ? '0 0 20px 0 rgba(16, 98, 75, 0.2)' : '0 0 20px 0 rgba(16, 98, 75, 0.2)',
        
    };

    const logoStyles = {
        apple: {
            marginTop: '30px',
            background: 'linear-gradient(200deg, rgba(27, 75, 72, 0.9), rgba(7, 43, 33,0.9))',
            width: '200px',
            padding: '10px',
            borderRadius: '10px',
            boxShadow: isToggled ? '0 0 10px 0 rgba(0, 197, 0, 0.15)' : '0 0 10px 0 rgba(0, 0, 0, 0.3)',
        },
        google: {
            marginTop: '30px',
            background: 'linear-gradient(200deg, rgba(27, 75, 72, 0.9), rgba(7, 43, 33,0.9))',
            width: '200px',
            padding: '10px',
            borderRadius: '10px',
            border: '1px solid rgba(180,180,180,0.1)',
            boxShadow: isToggled ? '0 0 5px 0 rgba(0, 0, 0, 0.3)' : '0 0 5px 0 rgba(0, 0, 0, 0.3)',
        }
    };

    const titleStylesThemed = {
        apple: {
            fontFamily: 'SF-Pro-Display-Thin, sans-serif',
            fontSize: '35px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'black',
            marginTop: '20px',
            marginBottom: '0px'
        },
        google: {
            fontFamily: 'ProductSansRegular, sans-serif',
            fontSize: '35px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'black',
            marginTop: '20px',
            marginBottom: '0px'
        }
    };

    const subtitleStylesThemed = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            color: isToggled ? 'rgb(190, 190, 190)' : 'rgb(92, 92, 92)',
            marginTop: '5px',
            marginBottom: '20px'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '20px',
            color: isToggled ? 'rgb(190, 190, 190)' : 'rgb(92, 92, 92)',
            marginTop: '5px',
            marginBottom: '20px'
        }
    };

    const projectDescriptionStyles = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'rgb(0, 0, 0)',
            marginTop: '10px',
            paddingLeft: '23px',
            paddingRight: '23px'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '21px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'rgb(0, 0, 0)',
            marginTop: '10px',
            paddingLeft: '20px',
            paddingRight: '20px'
        }
    };

    const readVisitButtonsContainer = {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        marginTop: '0px',
        marginBottom: '40px'
    };
    
    const readMoreStyles = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: isToggled ? 'rgb(34, 34, 36)' : 'rgba(13, 79, 61, 0.85)',
            padding: '8px 12px',
            borderRadius: '20px',
            border: '1px solid rgba(180,180,180,0.3)'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '20px',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: isToggled ? 'rgb(34, 34, 36)' : '#0d4f3d',
            padding: '4px 12px',
            borderRadius: '20px',
            border: '1px solid rgba(180,180,180,0.3)'
        }
    };

    const visitSiteStyle = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: isToggled ? 'rgb(34, 34, 36)' : 'rgba(13, 79, 61, 0.85)',
            padding: '8px 12px',
            borderRadius: '20px',
            border: '1px solid rgba(180,180,180,0.3)'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '20px',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: isToggled ? 'rgb(34, 34, 36)' : '#0d4f3d',
            padding: '4px 12px',
            borderRadius: '20px',
            border: '1px solid rgba(180,180,180,0.3)'
        }
    };

    const toolStyles = {
        list: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
        },
        image: {
            width: '30px',
            height: '30px'
        },
        gimp: {
            width: '40px',
            height: '30px',
        }
    };

    return(
        <div style={projectThreeContainer}>
            <img style={logoStyles[theme]} src='AppContent/joystiqbrands.png' alt='joystiqbrands' />
            <h2 style={titleStylesThemed[theme]}>Product Landing Page</h2>
            <h2 style={subtitleStylesThemed[theme]}>Web Application</h2>
            <p style={projectDescriptionStyles[theme]}>
            Landing page built with React for the purpose of providing the legal and contact
            information required to be eligible for Test Flight deployment. The site also introduces the company mission,
            previews some app features, and invites potential beta-testers to our discord server.
            </p>

            

            <div style={readVisitButtonsContainer}>
                <Link to="/joystiqsite" style={{textDecoration: 'none'}}>
                    <button style={readMoreStyles[theme]}>read more</button>
                </Link>

                <a href="https://www.joystiq.gg">
                    <button style={visitSiteStyle[theme]}>visit site &#8594;</button>
                </a>

            </div>


            <div style={toolStyles['list']}>

                <img style={toolStyles['image']} src="SkillsLogos/react.png" alt="Skill8" />
                <img style={toolStyles['image']} src="SkillsLogos/javascript.png" alt="Skill4" />
                <img style={toolStyles['image']} src="SkillsLogos/html.png" alt="Skill10" />
                <img style={toolStyles['image']} src="SkillsLogos/css.png" alt="Skill6" />
                <img style={toolStyles['gimp']} src="SkillsLogos/gimp.png" alt="Skill9" />
                <img style={toolStyles['image']} src="SkillsLogos/git.png" alt="Skill9" />
                <img style={toolStyles['image']} src="SkillsLogos/vscode.webp" alt="Skill9" />
                
            </div>
   

        </div>
    );
};

export default ProjectCardThree;