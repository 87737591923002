import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ThemeToggler from './ThemeToggler';
import ModeToggler from './ModeToggler';


const Header = ({ isToggled, toggleHandler, theme, toggleThemeHandler, handleShowModal , setShowModal }) => {

    //states and useEffect hook handle hide on scroll
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [scrollDirection, setScrollDirection] = useState('none'); // 'up' or 'down'

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScrollTop > lastScrollTop) {
                setScrollDirection('down');
            } else {
                setScrollDirection('up');
            }
            setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollTop]);

    const headerStyles = {
        width: '80%',
        zIndex: '999',
        minWidth: '800px',
        position: 'fixed',
        top: scrollDirection === 'down' ? '-70px' : '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        transition: 'top 0.3s ease',
    };
    const handleThemeChange = (selectedTheme) => {
       toggleThemeHandler(selectedTheme);
      };


    //header styles based on theme
    const headerStylesThemed = {
        apple: {
            ...headerStyles,
            borderRadius: '100px',
            background: isToggled
                ? 'linear-gradient(to bottom, rgb(59, 57, 57), rgb(33, 33, 34))'
                : 'linear-gradient(to bottom, rgb(245, 245, 245), rgb(225, 225, 225))',
            border: isToggled
                ? '2px solid rgba(73, 68, 68, 0.2)'
                : '2px solid rgba(200, 200, 200, 0.4)',
            boxShadow: isToggled
                ? '0px 5px 5px rgba(139, 138, 138, 0.1)'
                : '0px 5px 5px rgba(80, 80, 80, 0.3)',
        },
        google: {
            ...headerStyles,
            borderRadius: '100px',
            background: isToggled ? 'rgb(27, 33, 45)' : 'rgb(230, 231, 255)',
            boxShadow: isToggled
                ? '0px 5px 5px rgba(139, 138, 138, 0.1)'
                : '0px 5px 5px rgba(80, 80, 80, 0.3)',
        },
        anon: {
            ...headerStyles,
            backgroundColor: isToggled ? 'rgb(40, 40, 40)' : 'rgb(215, 215, 215)',
            border: isToggled
                ? '2px solid rgba(200, 200, 200, 0.2)'
                : '2px solid rgba(0, 0, 0, 0.3)',
            boxShadow: isToggled
                ? '0px 0px 20px rgba(100, 100, 100, 0.3)'
                : '0px 0px 20px rgba(255, 255, 255, 0.4)',
            backgroundImage: isToggled 
            ? 'repeating-linear-gradient(0deg, transparent, transparent 2px, rgba(0, 0, 0) 2px, rgba(0, 0, 0) 7px)'
            : 'repeating-linear-gradient(0deg, transparent, transparent 2px, rgba(245, 245, 245, 0.6) 2px, rgba(235, 235, 235, 0.6) 7px)',
        }
    };

    //common header item styles
    const headerItemsStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '25px',
        marginLeft: '30px',
    };

    //header item styles based on theme
    const headerItemsStyleThemed = {
        apple: {
            ...headerItemsStyle,
            fontFamily: 'SF-Pro-Regular, sans-serif',
            fontSize: '18px',
        },
        google: {
            ...headerItemsStyle,
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '18px',
           
        },
        anon: {
            ...headerItemsStyle,
            fontFamily: 'unicaone, sans-serif',
            fontSize: '20px',
        },
    };
    
    //link styles based on theme (Home, About, Contact)
    const linkStyles = {
        apple: {
            textDecoration: 'none',
            color: isToggled ? 'rgb(181, 181, 181)' : 'rgb(105, 105, 105)',
            background: 'none',
            border: 'none'
        },
        google: {
            textDecoration: 'none',
            color: isToggled ? 'rgb(147, 157, 182)' : 'rgb(117, 117, 117)',
            background: 'none',
            border: 'none'
        },
        anon: {
            textDecoration: 'none',
            color: isToggled ? 'rgb(201, 201, 201)' : 'rgb(25, 25, 25)',
            background: 'none',
            border: 'none',
            textTransform: 'uppercase',
        }
    };

    const homeButtonStylesThemed = {
        apple: {
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            background: 'linear-gradient(to bottom, #b9b9b9, #898989)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontFamily: 'SF-Pro-Regular, sans-serif',
            fontSize: '24px',
            textTransform: 'uppercase',
            marginLeft: '15px',
        },
        google: {
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            background: ' linear-gradient(30deg, #1A73E8 9.29%, #40A2FF 48.23%, #A8BEFF 82.56%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '24px',
            textTransform: 'uppercase',
            marginLeft: '15px',
        },
        anon: {
            width: '45px',
            height: '45px',
            backgroundColor: isToggled ?  'rgb(222, 222, 222)' : 'rgb(44, 44, 44)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: isToggled ? 'black' : 'white',
            fontFamily: 'unicaone, sans-serif',
            fontSize: '32px',
            marginLeft: '15px',
            border: isToggled ? '2px outset gray' : '2px inset gray',
            boxShadow: isToggled ? '0px 0px 5px rgba(238, 238, 238, 0.4)' : '0px 0px 10px rgba(20,20,20,0.4)',
        }
    };


    return(
        <header style={headerStylesThemed[theme]}>

            <div style={{display: 'flex'}}>

                <Link to="/" style={{textDecoration: 'none'}}>
                    <div style={homeButtonStylesThemed[theme]}>
                        CS
                    </div>
                </Link>

                <div style={headerItemsStyleThemed[theme]}>
                    <Link to="/" style={linkStyles[theme]}>Home</Link>
                    <Link to="/project-portfolio" style={linkStyles[theme]}>About</Link>
                    <button style={linkStyles[theme]} onClick={()=>setShowModal(true)}>Contact</button>
                </div>
            </div>
            
            <div style={{display: 'flex'}}>
                <ThemeToggler isToggled={isToggled} onThemeChange={handleThemeChange} theme={theme} />
                <ModeToggler onToggle={toggleHandler} isToggled={isToggled} theme={theme} />
            </div>

        </header>
    );
};

export default Header;