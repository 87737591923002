import React from 'react';

const OrbitPath = ({ isToggled }) => {

    const containerStyles = {
        width: '600px',
        height: '600px',
        position: 'absolute',
        right: '-140px',
        bottom: '-150px',
        zIndex: '0',
    };

    const svgStyles = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    // Define colors based on isToggled
    const outerCircleColors = isToggled
        ? {
            dark: 'rgb(80, 80, 80)',
            light: 'rgb(28, 28, 30)',
        }
        : {
            dark: 'lightgray',
            light: 'skyblue',
        };

    const circleStyles = {
        fill: '#87CEEB',
        filter: 'drop-shadow(0 0 8px white)',
    };

    return (
        <div style={containerStyles}>
            <svg style={svgStyles} width="550" height="550" viewBox="0 0 600 600">
                <defs>
                    <linearGradient id="darkGrayGradientOuter" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" stopColor={outerCircleColors.dark} stopOpacity="1">
                            <animate attributeName="stop-color" values={`${outerCircleColors.dark};${outerCircleColors.light};${outerCircleColors.dark}`} dur="10s" repeatCount="indefinite" />
                        </stop>
                    </linearGradient>
                    <linearGradient id="darkGrayGradientInner" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" stopColor={outerCircleColors.light} stopOpacity="1">
                            <animate attributeName="stop-color" values={`${outerCircleColors.light};${outerCircleColors.dark};${outerCircleColors.light}`} dur="10s" repeatCount="indefinite" />
                        </stop>
                    </linearGradient>
                </defs>
                <circle cx="300" cy="300" r="250" fill="none" stroke="url(#darkGrayGradientOuter)" strokeWidth="1" />
                <circle cx="300" cy="300" r="200" fill="none" stroke="url(#darkGrayGradientInner)" strokeWidth="1" />
                <circle r="12" style={circleStyles}>
                    <animateMotion dur="10s" repeatCount="indefinite">
                        <mpath href="#outerCirclePath" />
                    </animateMotion>
                </circle>
                <circle r="14" style={circleStyles}>
                    <animateMotion dur="16s" repeatCount="indefinite" keyPoints="1;0" keyTimes="0;1" calcMode="linear">
                        <mpath href="#innerCirclePath" />
                    </animateMotion>
                </circle>
                <path id="outerCirclePath" d="M 300,300 m -250,0 a 250,250 0 1,0 500,0 a 250,250 0 1,0 -500,0" fill="none" />
                <path id="innerCirclePath" d="M 300,300 m -200,0 a 200,200 0 1,0 400,0 a 200,200 0 1,0 -400,0" fill="none" />
            </svg>
        </div>
    );
};

export default OrbitPath;
