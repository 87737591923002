import '../styles/Utility/AnimatedLine.css';

const AnimatedLine = ({ lineNumber, isToggled }) => {
  const getLineAttributes = (lineNumber) => {
    switch (lineNumber) {
      case 1:
        return (
          <svg className="animated-line" width="600" height="500" viewBox="0 0 600 500">
            <defs>
              <linearGradient id="gradientStroke1" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: isToggled ? 'rgba(160, 32, 240,0.6)' : '#e78aed', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: isToggled ? 'rgba(135,206,235,0.6)' : '#689ff2', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <path
              className="line"
              d="M 0 350 
                 l 150 0 
                 q 20 0 20 -20 
                 v 0 -100 
                 q 0 -20 20 -20 
                 l 100 0 
                 q 20 0 20 -20
                 l 0 -50
                 q 0 -20 20 -20
                 l 150 0
                 q 20 0 20 -20
                 l 0 -50
                
                 "
              fill="transparent"
              stroke="url(#gradientStroke1)"
              strokeWidth="2"
            />
          </svg>
        );
      case 2:
        return (
          <svg className="animated-line-two" width="600" height="500" viewBox="0 0 600 500">
            <defs>
              <linearGradient id="gradientStroke2" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: isToggled ? 'rgba(221, 132, 227,0.6)' : '#d768f2', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: isToggled ? 'rgba(135,206,235,0.6)' : '#77d7ed', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <path
              className="line"
              d="M 600 350 
                l -180 0 
                q -20 0 -20 -20 
                v 0 -50 
                q 0 -20 -20 -20 
                l -50 0 
                q -20 0 -20 -20
                l 0 -150
                q 0 -20 -20 -20
                l -150 0
                q -20 0 -20 -20
                l 0 -50"
              fill="transparent"
              stroke="url(#gradientStroke2)"
              strokeWidth="2"
            />
          </svg>

        );
      default:
        return (
          <svg className="animated-line" width="600" height="500" viewBox="0 0 600 500">
            <defs>
              <linearGradient id="gradientStrokeDefault" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: 'purple', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: 'skyblue', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <path
              className="line"
              d="M 0 350 
                 l 150 0 
                 q 20 0 20 -20 
                 v 0 -100 
                 q 0 -20 20 -20 
                 l 100 0 
                 q 20 0 20 -20
                 l 0 -50
                 q 0 -20 20 -20
                 l 150 0
                 q 20 0 20 -20
                 l 0 -50"
              fill="transparent"
              stroke="url(#gradientStrokeDefault)"
              strokeWidth="2"
            />
          </svg>
        );
    }
  };

  return getLineAttributes(lineNumber);
};

export default AnimatedLine;
