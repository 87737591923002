import './App.css';
import './global.css';
import HomePage from './components/HomePage';
import Header from './utility/Header';
import Footer from './utility/Footer';
import ProjectOnePage from './components/ProjectPages/ProjectOnePage';
import ProjectTwoPage from './components/ProjectPages/ProjectTwoPage';
import ProjectPortfolio from './components/ProjectPages/ProjectPortfolio';
import JoystiqSite from './components/ProjectPages/JoystiqSite';
import PocketCipherSite from './components/ProjectPages/PocketCipherSite';
import ContactModal from './utility/ContactModal';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ParticlesBackground from './utility/ParticlesBackground';

/* TO DO LIST

  PORTFOLIO SITE
  -

  JOYSTIQ SITE
  -center play button on avatar demo
  -swap twitter for X logos

*/

function App() {

  //modal states
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  //light and dark mode toggler
  const [isToggled, setIsToggled] = useState(true);
  const toggleHandler = () => {
    setIsToggled(prevState => !prevState);
  };

  //theme toggler - apple, google, anon
  const [theme, setTheme] = useState('google');
  const toggleThemeHandler = (newTheme) => {
    setTheme(newTheme);
  };

  //hook for setting background color
  useEffect(() => {
    let backgroundStyle;
    if (theme === 'google') {
        backgroundStyle = isToggled ?
            'linear-gradient(to bottom, rgb(73, 77, 156), rgb(28, 28, 30))' :
            'linear-gradient(to bottom, rgb(118, 124, 245), rgb(227, 228, 255))';
    } else if (theme === 'apple') {
        backgroundStyle = isToggled ?
            'rgb(28, 28, 30)' :
            'rgb(245, 245, 245)';
    } else {
        backgroundStyle = 'transparent'; //transparent for anon 3D scene
    }

    document.body.style.transition = 'background 0.3s ease, color 0.5s ease';
    document.body.style.background = backgroundStyle;
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundAttachment = 'fixed';

    // Clean up when component unmounts or when effect changes
      return () => {
        document.body.style.backgroundSize = '';
        document.body.style.backgroundAttachment = '';
        };
  }, [isToggled, theme]);


  return (
    <Router>
      <div className="App">
  
        {theme === 'anon' && 
          <ParticlesBackground
           isToggled={isToggled} 
           id={isToggled ? "tsparticlesdark" : "tsparticleslight"}
           />
        }

        <Header 
          isToggled={isToggled} 
          theme={theme} 
          toggleHandler={toggleHandler} 
          toggleThemeHandler={toggleThemeHandler}
          showModal={showModal}
          setShowModal={setShowModal}
        />

        <ContactModal show={showModal} handleShowModal={handleShowModal} onHide={handleCloseModal} theme={theme} isToggled={isToggled} />

        <Routes>
          
          <Route path="/" element={<HomePage isToggled={isToggled} theme={theme} />} />
          <Route path="/pocketcipher" element={<ProjectOnePage isToggled={isToggled} theme={theme} />} />
          <Route path="/joystiqapp" element={<ProjectTwoPage isToggled={isToggled} theme={theme} />} />
          <Route path="/joystiqsite" element={<JoystiqSite isToggled={isToggled} theme={theme} />} />
          <Route path="/pocketciphersite" element={<PocketCipherSite isToggled={isToggled} theme={theme} />} />
          <Route path="/project-portfolio" element={<ProjectPortfolio isToggled={isToggled} theme={theme} />} />
        </Routes>

        <Footer isToggled={isToggled} theme={theme} />
        
      </div>

    </Router>
  );
}

export default App;