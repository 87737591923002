import '../../styles/ProjectPages/ProjectOnePage.css';
import '../../styles/ProjectPages/JoystiqSite.css';
import '../../styles/ProjectPages/ProjectPortfolio.css';

import SampleCard from './SampleCard';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import NamePath from '../../utility/NamePath';
import OrbitPath from '../../utility/OrbitPath';
import CardBorderSVG from '../../utility/CardBorderSVG';

const ProjectPortfolio = ({ isToggled, theme }) => {

    useEffect(() => {
        setIsNameVisible(true);
        window.scrollTo(0, 0); // Reset scroll position when this component mounts
        }, []);

    const [isNameVisible, setIsNameVisible] = useState(false);
    const name = "CONNOR SOTTOSANTI";

    //back button
    const backButtonStyles = {
        apple: {
            background: 'none',
            border: 'none',
            color: 'rgb(10, 132, 255)',
            fontSize: '20px',
            cursor: 'pointer'
        },
        google: {
            background: 'none',
            border: 'none',
            color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(255, 255, 255)',
            fontSize: '20px',
            cursor: 'pointer'
        },
        anon: {
            marginTop: '10px',
            fontFamily: 'unicaone, sans-serif',
            position: 'relative',
            zIndex: 2,
            fontSize: '18px',
            cursor: 'pointer',
            backgroundColor: (isToggled ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.8'), 
            color: (isToggled ? 'black' : 'white'),
            border: isToggled 
            ? '2px solid black'
            : '2px solid white',
        }

    };

    //container
    const portfolioContainerStyles = {
        apple: {
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
            width: '1000px',
            minWidth: '900px',
            paddingBottom: '50px',
            marginBottom: '30px',
            borderRadius: '30px',
            backgroundColor: isToggled ? 'rgb(44, 44, 46)' : 'rgb(255, 255, 255)',
            boxShadow: isToggled ? '0px 0px 25px rgba(0, 0, 0, 0.3)' : '0px 0px 25px rgba(0, 0, 0, 0.3)',
            transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
        },
        google: {
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
            width: '1000px',
            minWidth: '900px',
            paddingBottom: '50px',
            marginBottom: '30px',
            borderRadius: '30px',
            backgroundColor: isToggled ? 'rgb(33, 39, 54)' : 'rgb(230, 231, 255)',
            boxShadow: isToggled ? '0px 0px 25px rgba(0, 0, 0, 0.3)' : '0px 0px 25px rgba(0, 0, 0, 0.3)',
            transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
        },
        anon: {
           zIndex: '2',
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
            width: '1000px',
            minWidth: '900px',
            paddingBottom: '50px',
            marginBottom: '30px',
            backgroundColor: isToggled ? 'rgba(19, 19, 19, 0.9)' : 'rgba(255, 255, 255, 0.9)',
            border: `2px solid ${isToggled ? 'white' : 'black'}`,
            boxShadow: isToggled ? '0px 0px 25px rgba(0, 0, 0, 0.9)' : '0px 0px 25px rgba(0, 0, 0, 0.9)',
            transition: 'background-color 0.3s ease, box-shadow 0.5s ease', 
        }
        
    };

    //title banner background
    const bannerStyles = {
        apple: {
            width: '1000px',
            height: '200px',
            background: isToggled ? 'linear-gradient(20deg, rgb(64, 64, 64), rgb(104, 104, 104))' : 'linear-gradient(20deg, rgb(124, 124, 124), rgb(224, 224, 224))' ,
            borderTopLeftRadius: '30px',
            borderTopRightRadius: '30px'
        },
        google: {
            width: '1000px',
            height: '200px',
            //background: isToggled ? 'rgb(27, 33, 45)' : 'rgb(237, 242, 252)',
            backgroundImage: isToggled ? 'radial-gradient(circle, rgb(39, 48, 71) 50%, rgb(27, 33, 45) 100%)' : 'radial-gradient(circle, rgb(192, 195, 252) 50%, rgb(167, 171, 252) 100%)',
            borderTopLeftRadius: '30px',
            borderTopRightRadius: '30px',
            border: isToggled ? '1px solid rgba(114, 172, 250, 0.4)' : '1px solid rgba(57, 69, 158, 0.4)',
        },
        anon: {
            width: '996px',
            height: '160px',
            background: isToggled ? 'rgba(10,10,10,0.8)' : 'rgba(220,220,220,0.8)',
        }
    };

    //banner text (name)
    const ppBannerTitle = {
        apple: {
            marginLeft: '300px',
            marginTop: '60px',
            fontFamily: "'SF-Pro-Display-Thin', sans-serif",
            fontSize: '60px',
            backgroundImage: isToggled ? 'linear-gradient(90deg, rgb(169, 31, 31), #a856fb, rgb(169, 31, 31)' : 
            'linear-gradient(90deg, rgb(255, 0, 0), #eaff00, rgb(255, 0, 0))',
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            color: 'transparent',
            animation: 'animateGradient 6s linear infinite alternate',
            WebkitTextFillColor: 'transparent',
            backgroundSize: '200% auto'
        },
        google: {
            marginLeft: '300px',
            marginTop: '60px',
            fontFamily: "ProductSansLight, sans-serif",
            fontSize: '60px',
            backgroundImage: isToggled ? 'linear-gradient(15deg, rgb(33, 123, 254) 0%, rgb(194, 109, 227) 25%, rgb(255, 64, 70) 90%)' :
            'linear-gradient(10deg, #1A73E8 9.29%, #7940ff 30.23%, #A8BEFF 80.56%)',
            WebkitBackgroundClip: 'text',
            backgroundClip: 'text',
            color: 'transparent',
            WebkitTextFillColor: 'transparent',
            backgroundSize: '200% auto'
        },
        anon: {

        }
        
      };

    //section title
    const appNameStyles = {
        fontSize: '30px',
        marginTop: '30px',
        marginBottom: '10px',
    };

    //section title
    const appNameStylesThemed = {
        apple: {
            ...appNameStyles,
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(44, 44, 46)',
            marginLeft: '90px',

        },
        google: {
            ...appNameStyles,
            fontFamily: 'ProductSansLight, sans-serif',
            color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(44, 44, 46)',
            marginLeft: '98px',
        },
        anon: {
            ...appNameStyles,
            fontFamily: 'unicaone, sans-serif',
            color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(44, 44, 46)',
            textTransform: 'uppercase',
            marginLeft: '90px',
        }
    }

    const titleDividerStyles = {
        apple: {
            margin: '5px 0px 40px 94px',
            height: '3px',
            borderRadius: '3px',
            width: '280px',
            background: 'linear-gradient(160deg, rgba(64, 64, 66, 0.7), rgba(225, 225, 225, 0.7))'
        },
        google: {
            margin: '5px 0px 40px 102px',
            height: '2px',
            borderRadius: '3px',
            width: '260px',
            background: isToggled ? 'rgba(230, 230, 230, 0.6)' : 'rgba(24, 24, 26, 0.4)'
        },
        anon: {
            margin: '5px 0px 40px 94px',
            height: '1px',
            width: '280px',
            background: isToggled ? 'rgba(255, 255, 255, 0.9)' : 'rgba(19, 19, 19, 0.9)',
        }
        
    };

    const dividerStyles = {
        margin: '40px auto 20px auto',
        height: theme === 'anon' ? '1px' : '2px',
        borderRadius: '3px',
        width: '800px',
        backgroundColor: isToggled
            ? 'rgb(60, 60, 60)'
            : 'rgb(180, 180, 180)',
    };

    const sectionTitleStyles = {
        apple: {
            margin: '0px 0px 10px 100px',
            fontFamily: 'SF-Pro-Text-Ultralight, sans-serif',
            fontSize: '30x',
            color: isToggled ? 'rgb(235, 235, 235' : 'rgb(80,80,80)',
        },
        google: {
            margin: '0px 0px 10px 100px',
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '30x',
            color: isToggled ? 'rgb(235, 235, 235' : 'rgb(80,80,80)',
        },
        anon: {
            margin: '0px 0px 10px 100px',
            fontFamily: 'unicaone, sans-serif',
            textTransform: 'uppercase',
            fontSize: '30x',
            color: isToggled ? 'rgb(235, 235, 235' : 'rgb(80,80,80)',
        }
    };

    const myContributionStyles = {
        apple: {
            margin: '0px 100px 0px 100px',
            fontFamily: 'SF-Pro-Text-Ultralight, sans-serif',
            fontSize: '20px',
            lineHeight: '26px',
            color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(0, 0, 0)' ,
        },
        google:{
            margin: '0px 100px 0px 100px',
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '22px',
            lineHeight: '26px',
            color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(0, 0, 0)' ,
        },
        anon: {
            margin: '0px 100px 0px 100px',
            fontFamily: 'unicaone, sans-serif',
            textTransform: 'uppercase',
            fontSize: '20px',
            lineHeight: '26px',
            color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(0, 0, 0)' ,
        }
        
    };

    const appleRefs = (
        <>
            <p style={myContributionStyles[theme]}>
                I first wanted to grasp the essence of iOS design. I started by looking up light and dark
                mode color schemes and found a helpful image of iOS card styles with different color
                modes show below.
            </p>

            <img src='AppContent/portfolioprocess/ioscards.png' className='cards-image' alt='ioscards'/>

            <p style={myContributionStyles[theme]}>
                I then went and imported the iOS font 'SF Pro' and a few of its differently sized 
                variants and recreated the styles using JavaScript, HTML, and CSS. Below are my card components.
            </p>

            <SampleCard />

            <p style={myContributionStyles[theme]}>
                I took inspiration from common iOS components like the account indicator in the top left - which
                I'm using as a home button, light and dark mode colorways, 
                and made sure to tie it all in with rounded corners and shadows. Below are some more 
                images I referenced when creating the site.
            </p>

            <div className='ios-ref-container'>
                <img src='AppContent/portfolioprocess/ipadimage.png' className='ipad-image' alt='ioscards'/>
                <img src='AppContent/portfolioprocess/iostoggler.png' className='toggler-image' alt='ioscards'/>
            </div>
        </>
    );

    const googleRefs = (
        <>
            <p style={myContributionStyles[theme]}>
                For the most recent theme, I took inspiration from Google's Gemini.
            </p>

            <img src='AppContent/portfolioprocess/geminiref1.png' className='gemini-ref-img' alt='geminireference'/>

            <p style={myContributionStyles[theme]}>
                I replicated the use of linear and radial gradients, as well as animated vector paths to create the wire-like animations.
                I imported Google's signature font "Product Sans" and adopted the smooth, rounded style - similar to iOS design. 
            </p>

            <img src='AppContent/portfolioprocess/geminiref2.png' className='gemini-ref-img' alt='geminireference'/>

            <p style={myContributionStyles[theme]}>
                I developed my own component that used vector graphics to create to orbiting circles like the design above, and
                created another component that used vector graphics to create card borders with a linear gradient. 
            </p>

            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', height:'500px', 
                gap:'100px', marginLeft: '350px', marginTop: '200px'}}>

                <div style={{position: 'relative'}}>
                    <OrbitPath isToggled={isToggled} />
                </div>

                <div style={{width: '318px', height: '298px', position: 'relative'}}>
                    <CardBorderSVG isToggled={isToggled} cardType={'rutgers'} />
                </div>

            </div>
            
            

            

            <img src='AppContent/portfolioprocess/geminiref3.png' className='gemini-ref-img' alt='geminireference'/>

        </>
    );

    return(
        <>
            {theme === 'apple' || theme === 'google' ? (
                <div className='back-page-container'>

                    <Link to="/" style={{textDecoration: 'none', marginBottom: '10px', marginRight: '900px'}}>
                        <button style={backButtonStyles[theme]}>&#x276E; back</button>
                    </Link>

                    <div style={portfolioContainerStyles[theme]}>

                        <div style={bannerStyles[theme]}>
                            {theme === 'google' && <NamePath isToggled={isToggled} />}
                            {theme !== 'google' && <h2 style={ppBannerTitle[theme]}>Connor Sottosanti</h2>}
                        </div>

                        {theme === 'apple' && <div className={isToggled ? 'animated-bottom-border-dark' : 'animated-bottom-border-light'} /> }
        
                        <div className='title-and-divider'>
                            <h2 style={appNameStylesThemed[theme]}>My Personal Portofilio</h2>
                            <div style={titleDividerStyles[theme]} />
                        </div>

                        <p style={myContributionStyles[theme]}>
                            I built this website for the purpose of demonstrating my technical ability
                            and design skill as well as showcasing some of my recent projects. The site offers three themes in light and dark mode. One 
                            theme is inspired by iOS, another by Google's Gemini, and the last is a futuristic design inspired by the movie "ANON".

                            The current selected theme is {theme === 'google' ? 'gemini' : theme} in {(isToggled ? 'dark' : 'light')} mode.
                            I will include some more details on the design process for the selected theme below as well as a list of tools I used to build the site.
                            <br />
                            <br />
                            I have several years of software engineering experience including building websites and mobile applications from 
                            scratch. I'm responsible for the product design and user interface development of 
                            all projects in this portfolio. I hope you enjoy checking out some of my recent work! 
                            Feel free to get in touch for any opportunities or inquiries.
                        </p>

                        <div className='contribution-tools-container'>
                            <div className='tool-container'>
                                <img className='tool-big-image' src="SkillsLogos/react.png" alt="Skill8" />
                                <img className='tool-big-image' src="SkillsLogos/javascript.png" alt="Skill4" />
                                <img className='tool-big-image' src="SkillsLogos/html.png" alt="Skill10" />
                                <img className='tool-big-image' src="SkillsLogos/css.png" alt="Skill6" />
                                <img className='tool-big-image' src="SkillsLogos/vscode.webp" alt="Skill9" />
                                <img className='tool-big-image' src="SkillsLogos/aws.png" alt="Skill11" />
                            </div>
                        </div>

                        <div className='project-page-divider' style={dividerStyles} />
                        <h2 style={sectionTitleStyles[theme]}>The Process</h2>

                        {theme === 'apple' && appleRefs}
                        {theme === 'google' && googleRefs}

                    </div>

                </div>

            ) : (

                <div className='back-page-container'>

                    <Link to="/" style={{textDecoration: 'none', marginBottom: '10px', marginRight: '900px'}}>
                        <button style={backButtonStyles[theme]}>&#x276E; back</button>
                    </Link>

                    <div style={portfolioContainerStyles[theme]}>

                        <div style={bannerStyles[theme]}>
                            <h2 className={`anon-porfolio-name ${isNameVisible ? 'typing-animation' : ''}`} style={{ color: (isToggled ? 'white' : 'black')}}>
                                {name.split('').map((char, index) => (
                                    <span key={index} style={{animationDelay: `${index * 0.1}s`}}>
                                    {char === ' ' ? '\u00A0' : char} {/* Replace space with non-breaking space */}
                                    </span>
                                ))}
                            </h2>
                        </div>
        
                        <div className='title-and-divider'>
                            <h2 style={appNameStylesThemed[theme]}>My Personal Portofilio</h2>
                            <div style={titleDividerStyles[theme]} />
                        </div>

                        <p style={myContributionStyles[theme]}>
                            I built this website for the purpose of demonstrating my technical ability
                            and design skill as well as showcasing some of my recent projects. The site offers three themes in light and dark mode. One 
                            theme is inspired by iOS, another by Google's Gemini, and the last is a futuristic design inspired by the movie "ANON".

                            The current selected theme is {theme === 'google' ? 'gemini' : theme} in {(isToggled ? 'dark' : 'light')} mode.
                            I will include some more details on the design process for the selected theme below as well as a list of tools I used to build the site.
                            <br />
                            <br />
                            I have several years of software engineering experience including building websites and mobile applications from 
                            scratch. I'm responsible for the product design and user interface development of 
                            all projects in this portfolio. I hope you enjoy checking out some of my recent work! 
                            Feel free to get in touch for any opportunities or inquiries.
                        </p>

                        <div className='contribution-tools-container'>
                            <div className='tool-container'>
                                <img className='tool-big-image' src="SkillsLogos/react.png" alt="Skill8" />
                                <img className='tool-big-image' src="SkillsLogos/javascript.png" alt="Skill4" />
                                <img className='tool-big-image' src="SkillsLogos/html.png" alt="Skill10" />
                                <img className='tool-big-image' src="SkillsLogos/css.png" alt="Skill6" />
                                <img className='tool-big-image' src="SkillsLogos/vscode.webp" alt="Skill9" />
                                <img className='tool-big-image' src="SkillsLogos/aws.png" alt="Skill11" />
                            </div>
                        </div>

                        <div className='project-page-divider' style={dividerStyles} />
                        <h2 style={sectionTitleStyles[theme]}>The Process</h2>

                        <p style={myContributionStyles[theme]}>
                            After completing the site's apple theme, I wanted to add an additional theme option.
                            I watched a Netflix movie called, "ANON", and while the movie was not my favorite,
                            I thought the futuristic tech designs were really cool. So I gathered some reference images
                            from the movie.
                        </p>

                        <div className='anon-ref-holder'>
                            <img src='AppContent/portfolioprocess/anonref2.jpg' className='anon-ref-image' alt='anonref'
                            style={{border: `2px solid ${isToggled ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.8)' }`}}/>
                            <img src='AppContent/portfolioprocess/anonref1.png' className='anon-ref-image' alt='anonref'
                            style={{border: `2px solid ${isToggled ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.8)' }`}}/>
                        </div>

                        <p style={myContributionStyles[theme]}>
                            The designs include sharp angles and black and white colorways. 
                            I imported the custom 'unica one' font, assigned sharp borders to components,
                            and added some typing animations to components with my name. A major aspect of the movie 
                            was the interconnected network they called "the ether", which held everyones
                            personal information. It was represented by a visual interface where files were stored
                            in this collection of 3D cubes that can be naviagated. I leveraged the threejs framework
                            to create an animated 3D background to resemble the ether shown below.
                        </p>

                        <div className='anon-ref-holder'>
                            <img src='AppContent/portfolioprocess/anonref3.png' className='anon-ref-image' alt='anonref'
                            style={{border: `2px solid ${isToggled ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.8)' }`}}/>
                        </div>

                        <p style={myContributionStyles[theme]}>
                            Below was the result of the threejs scene I developed. It closely resemembled the ether
                            and was animated in such a way that the the viewport seemed to be traveling through the the 3D background.
                            The component initialized a 3D scene that generated randomly sized cubes. It also generated vectors
                            that connect to different cubes at random. To animate the scene as if we were traveling in the ether,
                            I made the cubes travel forward to the front of the scene, deleting themselves as they pass the camera frame.
                            Once deleted, they will regenerate at the front, so the animation will continue infinitely. While I really liked
                            this animated background, I found it to be quite resource intensive, causing performance issues on some systems.
                            I instead leveraged the tsparticles library to create a similar, animated background that allows for a smoother user 
                            experience on the site.
                        </p>

                        <div className='anon-ref-holder'>
                            <img src='AppContent/portfolioprocess/old3Dbackground.png' alt='anonref'
                            style={{width: '800px', height: '420px', border: `2px solid ${isToggled ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.8)' }`}}/>
                        </div>

                        <p style={myContributionStyles[theme]}>
                            
                        </p>
                        
                    </div>
                </div>
            )}
        </>
    );

}

export default ProjectPortfolio;