const CardBorderSVG = ({ isToggled, cardType }) => {

    const borderStyles = {
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '2',
    };
    
    switch (cardType) {
        case 'profile':
            return(
                <svg style={borderStyles} width="600" height="300" viewBox="0 0 600 300">
                    <defs>
                        <linearGradient id="cardBorderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" style={{ stopColor: isToggled ? '#3c8fe3' : 'rgba(104, 113, 237)', stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: isToggled ? '#2E64DE' : 'rgba(124, 120, 204)', stopOpacity: 1 }} />
                        </linearGradient>
                    </defs>
                    <path
                        d="M 1 270
                        v 0 -239 
                        q 0 -30 30 -30
                        l 538 0 
                        q 30 0 30 30 
                        v 0 238
                        q 0 30 -30 30 
                        l -538 0
                        q -30 0 -30 -30"
                        fill="transparent"
                        stroke="url(#cardBorderGradient)"
                        strokeWidth="2"
                    />
                </svg>
            );
        
        case 'skills':
            return(
                <svg style={borderStyles} width="600" height="300" viewBox="0 0 600 300">
                    <defs>
                        <linearGradient id="cardBorderGradient2" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" style={{ stopColor: isToggled ? '#3c8fe3' : '#72adc4', stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: isToggled ? '#2E64DE' : '#689ff2', stopOpacity: 1 }} />
                        </linearGradient>
                    </defs>
                    <path
                        d="M 1 270
                        v 0 -239 
                        q 0 -30 30 -30
                        l 440 0 
                        q 30 0 30 30 
                        v 0 238
                        q 0 30 -30 30 
                        l -440 0
                        q -30 0 -30 -30"
                        fill="transparent"
                        stroke="url(#cardBorderGradient)"
                        strokeWidth="2"
                    />
                </svg>
            );

        case 'rutgers':
            return(
                <svg style={borderStyles} width="600" height="300" viewBox="0 0 600 300">
                    <defs>
                        <linearGradient id="cardBorderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" style={{ stopColor: isToggled ? '#3c8fe3' : '#72adc4', stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: isToggled ? '#2E64DE' : '#689ff2', stopOpacity: 1 }} />
                        </linearGradient>
                    </defs>
                    <path
                        d="M 1 270
                        v 0 -239 
                        q 0 -30 30 -30
                        l 258 0 
                        q 30 0 30 30 
                        v 0 238
                        q 0 30 -30 30 
                        l -258 0
                        q -30 0 -30 -30"
                        fill="transparent"
                        stroke="url(#cardBorderGradient)"
                        strokeWidth="2"
                    />
                </svg>
            );
    

        default:
            return(
                <svg style={borderStyles} width="600" height="300" viewBox="0 0 600 300">
                    <defs>
                        <linearGradient id="cardBorderGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                        <stop offset="0%" style={{ stopColor: isToggled ? '#3c8fe3' : '#72adc4', stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: isToggled ? '#2E64DE' : '#689ff2', stopOpacity: 1 }} />
                        </linearGradient>
                    </defs>
                    <path
                        d="M 1 270
                        v 0 -240 
                        q 0 -28 31 -29
                        l 433 0 
                        q 38 0 34 35 
                        v 0 235
                        q 0 30 -30 28 
                        l -444 0
                        q -25 0 -24 -29"
                        fill="transparent"
                        stroke="url(#cardBorderGradient)"
                        strokeWidth="2"
                    />
                </svg>
            );
        };
};

export default CardBorderSVG;