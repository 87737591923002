import '../../../styles/HomePageCards/AnonCards/AnonSkillsCard.css';
import React, { useState } from 'react';

const AnonSkillsCard = ({ isToggled }) => {

    const [startIndex, setStartIndex] = useState(0);
    const skills = [
        'javascript.png', 'html.png', 'css.png', 'swift.png', 'java.png', 'sql.png', 'react.png',
        'git.png', 'python.png', 'figma.png', 'balsamiq.png', 'gimp.png',
        'xcode.png', 'vscode.webp'
    ];

    const handlePrevClick = () => {
        setStartIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : skills.length - 1));
    };

    const handleNextClick = () => {
        setStartIndex((prevIndex) => (prevIndex < skills.length - 1 ? prevIndex + 1 : 0));
    };

    const visibleSkills = skills.slice(startIndex, startIndex + 4);

    const anonSkillsContainer = {
        margin: '0px',
        textAlign: 'center',
        width: '500px',
        minWidth: '490px',
        height: '300px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: isToggled
            ? 'rgba(0, 0, 0, 0.8)'
            : 'rgb(255, 255, 255, 0.8)',
        border: isToggled 
            ? '2px solid rgba(255,255,255,0.9)'
            : '2px solid rgba(0,0,0,0.6)',
        boxShadow: isToggled
            ? '0px 0px 20px rgba(207, 207, 207, 0.2)'
            : '0px 0px 20px rgba(0, 0, 0, 0.3)',
        transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
    };

    const titleStyles = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '46px',
        marginTop: 0,
        marginBottom: 0,
        paddingTop: '25px',
        color: isToggled
        ? 'rgba(255,255,255,0.9)'
        : 'rgba(0,0,0,0.9)',
    };

    const skillDescription = {
        color: isToggled 
        ? 'rgb(150, 150, 150)' : 'rgb(67, 67, 67)',
        fontSize: '22px',
        fontFamily: 'unicaone, sans-serif',
        marginTop: '10px',

    };

    return(

        <div style={anonSkillsContainer}>
            <h3 style={titleStyles}>My Skills</h3>
            <p style={skillDescription}>BELOW ARE SOME THINGS I AM SKILLED AT. </p>


            <div className='skills-main-container'>

                <div className="navigation-arrows" style={{paddingLeft: (startIndex === 0 ? '25px': 0)}}>
                    {startIndex > 0 && <button className='chevron-button' style={{color: (isToggled ? 'white' : 'black')}} 
                    onClick={handlePrevClick}>&#10094;</button>}
                </div>

                <div className="skills-anon-container">
                    {visibleSkills.map((skill, index) => (

                        <img
                        key={index}
                        className={skill === 'git.png' ? 'git-image' :
                        skill === 'figma.png' ? 'figma-image' :
                        skill === 'gimp.png' ? 'gimp-image' : 
                        skill === 'vscode.webp' ? 'vscode-image' : 
                        skill === 'sql.png' ? 'sql-image' :
                        skill === 'java.png' ? 'java-image' :
                        'skill-anon-image'}
                        src={`SkillsLogos/${skill}`}
                        alt={`Skill${startIndex + index + 1}`}
                    />

                    ))}
                </div>
    
                <div className="navigation-arrows" style={{paddingRight: (startIndex === skills.length - 4 ? '40px': 0)}}>
                    {startIndex < skills.length - 4 && <button className='chevron-button' style={{color: (isToggled ? 'white' : 'black')}}
                    onClick={handleNextClick}>&#10095;</button>}

                </div>
            </div>


        </div>

    );
};

export default AnonSkillsCard;