import { Link } from 'react-router-dom';

const JoystiqSiteAnonCard = ({ isToggled }) => {

    const projectTwoContainer = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '400px',
        minWidth: '390px',
        height: '600px',
        ...(isToggled
            ? {
                background: 'linear-gradient(to bottom, rgba(26, 77, 72, 0.95), rgba(16, 98, 75, 0.95))'
              }
            : {
                backgroundColor: 'rgba(198, 245, 226, 0.9)'
              }),

        border: isToggled ? '1px solid rgba(255, 255, 255, 0.3)' : '1px solid rgba(0, 0, 0, 0.3)',
        boxShadow: isToggled
            ? '0 0 30px 0 rgba(16, 98, 75, 0.2)'
            : '0 0 30px 0 rgba(0, 0, 0, 0.2)',
        
    };

    const brandStyle = {
        marginTop: '30px',
        background: 'linear-gradient(200deg, #1B4B48, #072B21)',
        width: '200px',
        padding: '10px',
        boxShadow: '0 0 10px 0 rgba(0, 197, 0, 0.4)'
    };

    const titleStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '35px',
        color: isToggled ? 'white' : 'black',
        marginTop: '20px',
        marginBottom: '0px',
    };
    
    const subtitleStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        color: isToggled ? 'rgb(200,200,200)' : 'rgb(80,80,80)',
        marginTop: '5px',
        marginBottom: '20px',
    };

    const descriptionStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        color: isToggled ? 'rgb(240,240,240)' : 'rgb(20,20,20)',
        marginTop: '10px',
        marginBottom: '25px',
        paddingLeft: '23px',
        paddingRight: '23px',
    };

    const buttonContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        marginTop: '0px',
        marginBottom: '35px',
    };
    
    const readMoreStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        cursor: 'pointer',
        color: isToggled ? 'white' : 'white',
        backgroundColor: isToggled ? 'rgb(44, 44, 46)' : '#0d4f3d' ,
        height: '40px',
        padding: '0px 12px',
        border: isToggled ? '1px solid rgba(180,180,180,0.3)' : '1px solid rgba(20,20,20,0.3)' 
    };

    const visitSiteStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        cursor: 'pointer',
        color: 'white',
        backgroundColor: isToggled ? 'rgb(44, 44, 46)' : '#0d4f3d',
        height: '40px',
        padding: '0px 12px',
        border: '1px solid rgba(180,180,180,0.3)'
    };

    const toolStyles = {
        list: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
        },
        image: {
            width: '30px',
            height: '30px'
        },
        gimp: {
            width: '40px',
            height: '30px',
        },
        figma: {
            width: '35px',
            height: '25px',
            marginRight: '-5px'
        },
        blender: {
            width: '33px',
            height: '30px'
        },
        git: {
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            backgroundColor: isToggled ? 'gray' : 'rgb(224, 254, 233)'
        },
        vscode: {
            width: '25px', 
            height: '25px'
        }
    };

    return(
        <div style={projectTwoContainer}>

            <img src='AppContent/joystiqbrands.png' style={brandStyle} alt='joystiqbrands' />

            <h2 style={titleStyle}>PRODUCT LANDING PAGE</h2>

            <h2 style={subtitleStyle}>WEB APPLICATION</h2>

            <p style={descriptionStyle}>
            LANDING PAGE BUILT WITH REACT FOR THE PURPOSE OF PROVIDING THE LEGAL AND CONTACT 
            INFORMATION REQUIRED TO BE ELIGIBLE FOR TEST FLIGHT DEPLOYMENT. THE SITE ALSO INTRODUCES THE
            COMPANY MISSION, PREVIEWS SOME APP FEATURES, AND INVITES POTENTIAL BETA-TESTERS TO OUR DISCORD SERVER.
            </p>

            <div style={buttonContainerStyle}>
                <Link to="/joystiqsite" style={{textDecoration: 'none'}}>
                    <button style={readMoreStyle}>READ MORE</button>
                </Link>

                <a href="https://www.joystiq.gg">
                    <button style={visitSiteStyle}>VISIT SITE &#8594;</button>
                </a>
            </div>

            <div style={toolStyles['list']}>
                <img style={toolStyles['image']} src="SkillsLogos/react.png" alt="Skill8" />
                <img style={toolStyles['image']} src="SkillsLogos/javascript.png" alt="Skill4" />
                <img style={toolStyles['image']} src="SkillsLogos/html.png" alt="Skill10" />
                <img style={toolStyles['image']} src="SkillsLogos/css.png" alt="Skill6" />
                <img style={toolStyles['gimp']} src="SkillsLogos/gimp.png" alt="Skill9" />
                <img style={toolStyles['image']} src="SkillsLogos/git.png" alt="Skill6" />
                <img style={toolStyles['vscode']} src="SkillsLogos/vscode.webp" alt="Skill6" />
            </div>


        </div>
    );
};

export default JoystiqSiteAnonCard;