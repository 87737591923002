import '../../../styles/HomePageCards/AnonCards/AnonProfileCard.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

const AnonProfileCard = ({ isToggled }) => {

    const [isNameVisible, setIsNameVisible] = useState(false);
    const name = "CONNOR SOTTOSANTI";
  
    useEffect(() => {
      // Trigger animation on component mount
      setIsNameVisible(true);
    }, []);

    const readMoreStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        cursor: 'pointer',
        marginTop: '15px',
        color: isToggled ? 'white' : 'white',
        backgroundColor: isToggled ? 'rgba(85, 85, 85)' : 'rgb(124, 124, 126)' ,
        padding: '4px 12px',
        border: isToggled ? '1px solid rgba(180,180,180,0.3)' : '1px solid rgba(20,20,20,0.3)' 
    };


    return(

        <div className={isToggled ? "borderedDivDark" : "borderedDivLight"}>

            {/* <h2 className='anon-name' style={{ color: (isToggled ? 'white' : 'black')}}>CONNOR SOTTOSANTI</h2> */}


            <h2 className={`anon-name ${isNameVisible ? 'typing-animation' : ''}`} style={{ color: (isToggled ? 'white' : 'black')}}>
              {name.split('').map((char, index) => (
                <span key={index} style={{animationDelay: `${index * 0.1}s`}}>
                  {char === ' ' ? '\u00A0' : char} {/* Replace space with non-breaking space */}
                </span>
              ))}
            </h2>


            <h3 className='anon-job-title'>PERSONAL PORTFOLIO</h3>
            <p className='anon-description' style={{ color: (isToggled ? 'rgb(150, 150, 150)' : 'rgb(67, 67, 67)')}}>
            THANKS FOR CHECKING OUT MY SITE! I MADE IT WITH REACT TO SHOWCASE SOME OF MY RECENT PROJECTS.
            TO LEARN MORE ABOUT HOW I BUILT THIS SITE, CLICK BELOW.
            </p>

            <Link to="/project-portfolio" style={{textDecoration: 'none'}}>
              <button style={readMoreStyle}>READ MORE</button>
            </Link>

        </div>

    );
};

export default AnonProfileCard;