import { Link } from 'react-router-dom';

const PCSiteAnonCard = ({ isToggled }) => {

    //conditional styles
    const projectTwoContainer = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '400px',
        minWidth: '390px',
        height: '600px',
        ...(isToggled
            ? {
                background: 'linear-gradient(to bottom, rgba(166, 107, 255, 0.95), rgba(205, 93, 252, 0.95))'
              }
            : {
                backgroundColor: 'rgba(233, 219, 248, 0.9)'
              }),

        border: isToggled ? '1px solid rgba(255, 255, 255, 0.3)' : '1px solid rgba(0, 0, 0, 0.3)',
        boxShadow: isToggled
            ? '0 0 30px 0 rgba(205, 93, 252, 0.2)'
            : '0 0 30px 0 rgba(0, 0, 0, 0.2)',
        
    };


    const logoContainerStyles = {
        display: 'flex',
        alignItems: 'center',
        height: '60px',
        gap: '0px',
        marginTop: '30px',
        padding: '2px 10px',
        background: 'linear-gradient(200deg, rgba(190, 50, 251, 0.8), rgba(77, 41, 151, 0.8))',
        boxShadow: '0px 0px 10px rgba(243, 73, 243, 0.6)'
    };

    const keyLogoStyles = {
        marginRight: '10px',
        width: '50px',
        height: '25px',
    };

    const keyLogoTitleStyles = {
        fontFamily: 'SF-Pro-Text-Thin, sans-serif',
        fontSize: '22px',
        marginTop: '8px',
        backgroundImage: 'linear-gradient(to bottom, #ffe656, #cdae00)',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        color: 'transparent'
    };

    const titleStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '35px',
        color: isToggled ? 'white' : 'black',
        marginTop: '20px',
        marginBottom: '0px',
    };
    
    const subtitleStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        color: isToggled ? 'rgb(200,200,200)' : 'rgb(80,80,80)',
        marginTop: '5px',
        marginBottom: '20px',
    };

    const descriptionStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        color: isToggled ? 'rgb(240,240,240)' : 'rgb(20,20,20)',
        marginTop: '10px',
        marginBottom: '52px',
        paddingLeft: '23px',
        paddingRight: '23px',
    };

    const buttonContainerStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        marginTop: '0px',
        marginBottom: '95px',
    };

    const readMoreStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        cursor: 'pointer',
        color: isToggled ? 'white' : 'white',
        backgroundColor: isToggled ? 'rgb(44, 44, 46)' : 'rgba(77, 41, 151, 0.9)',
        padding: '4px 12px',
        border: isToggled ? '1px solid rgba(180,180,180,0.3)' : '1px solid rgba(20,20,20,0.3)' 
    };

    const visitSiteStyle = {
        fontFamily: 'unicaone, sans-serif',
        fontSize: '20px',
        cursor: 'pointer',
        color: 'white',
        backgroundColor: isToggled ? 'rgb(44, 44, 46)' : 'rgba(77, 41, 151, 0.9)',
        padding: '4px 12px',
        border: '1px solid rgba(180,180,180,0.3)'
    };

    const toolStyles = {
        list: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
        },
        image: {
            width: '30px',
            height: '30px'
        },
        vscode: {
            width: '25px', 
            height: '25px'
        }
    };

    return(
        <div style={projectTwoContainer}>

            <div style={logoContainerStyles}>
                <img src='AppContent/KeyLogoTransparent.png' style={keyLogoStyles} alt='pc-logo' />
                <h3 style={keyLogoTitleStyles}>PocketCipher</h3>
            </div>

            <h2 style={titleStyle}>PRODUCT LANDING PAGE</h2>

            <h2 style={subtitleStyle}>WEB APPLICATION</h2>

            <p style={descriptionStyle}>
            LANDING PAGE BUILT WITH REACT FOR THE PURPOSE OF PROVIDING THE LEGAL AND CONTACT 
            INFORMATION REQUIRED FOR THE POCKETCIPHER APP TO BE ELIGIBLE FOR APP STORE DEPLOYMENT. 
            </p>

            <div style={buttonContainerStyle}>
                <Link to="/pocketciphersite" style={{textDecoration: 'none'}}>
                    <button style={readMoreStyle}>READ MORE</button>
                </Link>

                <a href='http://pocket-cipher-static-files.s3-website-us-east-1.amazonaws.com/'>
                    <button style={visitSiteStyle}>VISIT SITE &#8594;</button>
                </a>
            </div>

            <div style={toolStyles['list']}>
                <img style={toolStyles['image']} src="SkillsLogos/react.png" alt="Skill8" />
                <img style={toolStyles['image']} src="SkillsLogos/javascript.png" alt="Skill4" />
                <img style={toolStyles['image']} src="SkillsLogos/html.png" alt="Skill10" />
                <img style={toolStyles['image']} src="SkillsLogos/css.png" alt="Skill6" />
                <img style={toolStyles['vscode']} src="SkillsLogos/vscode.webp" alt="Skill6" />
            </div>

        </div>
    );
};

export default PCSiteAnonCard;