import CardBorderSVG from '../../utility/CardBorderSVG';
import OrbitPath from '../../utility/OrbitPath';

const RutgersCard = ({ isToggled, theme }) => {

    const jobContainerStyles = {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '320px',
        height: '300px',
        transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
        zIndex: '1', // Ensure RutgersCard is above OrbitPath but below CardBorderSVG
    };

    const jobContainerStylesThemed = {
        apple: {
            ...jobContainerStyles,
            borderRadius: '30px',
            backgroundColor: isToggled ? 'rgb(44, 44, 46)' : 'rgb(255, 255, 255)',
            boxShadow: isToggled ? '0px 0px 15px rgba(0, 0, 0, 0.3)' : '0px 0px 15px rgba(0, 0, 0, 0.3)',
        },
        google: {
            ...jobContainerStyles,
            borderRadius: '30px',
            background: isToggled ? 'radial-gradient(circle, rgb(40, 49, 66), rgb(27, 33, 45))' 
            : 'radial-gradient(circle, rgb(212, 214, 250), rgb(230, 231, 255))',
        },
        anon: {
            ...jobContainerStyles,
            backgroundColor: isToggled ? 'rgba(0, 0, 0, 0.8)' : 'rgb(255, 255, 255, 0.8)',
            border: isToggled ? '2px solid rgba(255,255,255,0.9)' : '2px solid black',
            boxShadow: isToggled ? '0px 0px 20px rgba(207, 207, 207, 0.2)' : '0px 0px 20px rgba(0, 0, 0, 0.3)',
        }
            
    };

    const titleDateContainerStyles = {
        height: '15px',
        display: 'flex',
        paddingTop: '15px',
        paddingLeft: '20px',
        paddingBottom: '15px',
        alignItems: 'baseline',
    }

    const jobTitleStyles = {
        apple: {
            fontFamily: 'SF-Pro-Regular, sans-serif',
            fontSize: '24px',
            margin: 0,
            color: isToggled ? 'rgb(255, 255, 255)' : 'rgb(44, 44, 46)',
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '24px',
            margin: 0,
            color: isToggled ? 'rgb(186, 204, 255)' : 'rgb(36, 148, 255)'
        },
        anon : {
            textTransform: 'uppercase',
            fontFamily: 'unicaone, sans-serif',
            fontSize: '24px',
            margin: 0,
            color: isToggled ? 'rgb(255, 255, 255)' : 'rgb(44, 44, 46)',
        }
        
    };

    const infoDateContainerStyles = {
        height: '15px',
        display: 'flex',
        paddingTop: '18px',
        paddingLeft: '20px',
        paddingBottom: '15px',
        alignItems: 'baseline',
    }

    const jobInfoStyles = {
        apple: {
            fontFamily: 'SF-Pro-Regular, sans-serif',
            fontSize: '22px',
            margin: 0,
            color: isToggled ? 'rgb(205, 205, 205)' : 'rgb(94, 94, 96)',
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '22px',
            margin: 0,
            color: isToggled ? 'rgb(168, 190, 255)' : 'rgb(45, 149, 247)'
        },
        anon : {
            textTransform: 'uppercase',
            fontFamily: 'unicaone, sans-serif',
            fontSize: '22px',
            margin: 0,
            color: isToggled ? 'rgb(255, 255, 255)' : 'rgb(44, 44, 46)',
        }
    };

    const dateStyles = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '16px',
            margin: 0,
            marginLeft: '10px',
            color: isToggled ? 'rgb(205, 205, 205)' : 'rgb(44, 44, 46)',
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '18px',
            margin: 0,
            marginLeft: '10px',
            color: isToggled ? 'rgb(255, 255, 255)' : 'rgb(44, 44, 46)',
        },
        anon : {
            textTransform: 'uppercase',
            fontFamily: 'unicaone, sans-serif',
            fontSize: '18px',
            margin: 0,
            marginLeft: '10px',
            color: isToggled ? 'rgb(255, 255, 255)' : 'rgb(44, 44, 46)',
        }
    };

    return(
        <div style={{ position: 'relative' }}>
            {theme === 'google' && <OrbitPath isToggled={isToggled} />} 
            <div style={jobContainerStylesThemed[theme]}>
                {theme === 'google' && <CardBorderSVG isToggled={isToggled} cardType={'rutgers'}/>} 
                <div style={titleDateContainerStyles}>
                    <h2 style={jobTitleStyles[theme]}>Rutgers University</h2>
                    <h3 style={dateStyles[theme]}>2019 - 2023</h3>
                </div>
                <div style={infoDateContainerStyles}>
                    <h2 style={jobInfoStyles[theme]}>Computer Science -</h2>
                    <h3 style={dateStyles[theme]}>B.A.</h3>
                </div>
                <img src="misc/rutgers.png" style={{ width: "160px", height: "160px", marginLeft: "27%", marginTop: "9%", opacity: "80%"}} alt="rutgerslogo" />
            </div>
        </div>
    );
};

export default RutgersCard;
