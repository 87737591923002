import "../styles/Utility/ModeToggler.css";

const ModeToggler = ({ isToggled, onToggle, theme }) => {

  const modeContainerStyles = {
    apple: {
      marginRight: '20px',
      display: 'flex',
      verticalAlign: 'middle',
      width: '170px',
      height: '50px',
      borderRadius: '100px',
      backgroundColor: isToggled ? '#4d4d4d' : 'rgb(247, 247, 247)',
      position: 'relative',
      boxShadow: isToggled ? '1px 1px 5px 1px rgba(0, 0, 0, 0.1)' : '1px 1px 5px 1px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
    },
    google: {
      marginRight: '20px',
      display: 'flex',
      verticalAlign: 'middle',
      width: '170px',
      height: '50px',
      borderRadius: '100px',
      backgroundColor: isToggled ? 'rgb(86, 98, 133)' : '#c0c3fc',
      position: 'relative',
      boxShadow: isToggled ? '1px 1px 10px 1px rgba(0, 0, 0, 0.25)' : '0px 0px 3px 0px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer',
    },
    anon: {
      marginRight: '20px',
      display: 'flex',
      verticalAlign: 'middle',
      width: '170px',
      height: '50px',
      backgroundColor: isToggled ?  'rgb(120, 120, 120, 0.8)' : 'rgba(244, 244, 244, 0.8)',
      position: 'relative',
      boxShadow: isToggled ? '0px 0px 5px rgba(238, 238, 238, 0.6)' : '0px 0px 10px rgba(20,20,20,0.4)',
      cursor: 'pointer',
    }
  };

  const switchColors = {
    apple: {
      backgroundColor: isToggled ? 'white' : 'rgb(200,200,200)'
    },
    google: {
      backgroundColor: isToggled ? 'rgb(179, 198, 252)' : 'rgb(129, 147, 199)'
    },
    anon: {
      backgroundColor: isToggled ? 'rgb(200,200,200)' : 'rgb(220,220,220)',
      border: isToggled ? '2px outset gray' : '2px outset gray',
      borderRadius: '0px'
    }
  };

  return (
    <div style={modeContainerStyles[theme]}>
      <div className={`mode-switch ${isToggled ? "mode-right-position" : "mode-left-position"}`} style={switchColors[theme]}></div>
      <input
        defaultChecked={!isToggled}
        onChange={onToggle}
        name="mode-switch"
        id="mode-left"
        type="radio"
        value="left"
        style={{ display: "none" }} // Hide the input visually
      />
      <label
        className="mode-left-label"
        htmlFor="mode-left"
      >
        <img src="misc/lightmodeicon.png" className="lightmodeicon" alt="left" />
      </label>

      <input
        defaultChecked={isToggled}
        onChange={onToggle}
        name="mode-switch"
        id="mode-right"
        type="radio"
        value="right"
        style={{ display: "none" }} // Hide the input visually
      />
      <label
        className="mode-right-label"
        htmlFor="mode-right"
      >
        <img src="misc/darkmodeicon.png" className="lightmodeicon" alt="right" />
      </label>
    </div>
  );
};


export default ModeToggler;
