import { Link } from 'react-router-dom';

const ProjectCardOne = ({ theme, isToggled }) => {

    //conditional styles
    const projectOneContainer = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '400px',
        minWidth: '380px',
        height: '600px',
        ...(isToggled
            ? {
                background: 'linear-gradient(to bottom, rgba(166, 107, 255, 0.7), rgba(205, 93, 252, 0.6))'
              }
            : {
                backgroundColor: 'rgb(233, 219, 248)'
              }),

        borderRadius: '30px',
        border: isToggled ? '1px solid rgba(128, 128, 128, 0.2)' : '1px solid rgba(0, 0, 0, 0.2)',
        boxShadow: isToggled ? '0 0 20px 0 rgba(205, 93, 252, 0.2)' : '0 0 20px 0 rgba(205, 93, 252, 0.2)',
        
    };

    const logoContainerStyles = {
        apple: {
            display: 'flex',
            alignItems: 'center',
            height: '60px',
            gap: '0px',
            marginTop: '30px',
            padding: '2px 10px',
            borderRadius: '10px',
            background: 'linear-gradient(200deg, rgba(190, 50, 251, 0.8), rgba(77, 41, 151, 0.8))',
            boxShadow: '0px 0px 10px rgba(243, 73, 243, 0.6)'

        },
        google: {
            display: 'flex',
            alignItems: 'center',
            height: '60px',
            gap: '0px',
            marginTop: '30px',
            padding: '2px 10px',
            borderRadius: '10px',
            background: 'linear-gradient(200deg, rgba(190, 50, 251, 0.8), rgba(77, 41, 151, 0.8))',
            border: '1px solid rgba(180,180,180,0.2)',
            boxShadow: isToggled ? '0 0 5px 0 rgba(0, 0, 0, 0.2)' : '0 0 5px 0 rgba(0, 0, 0, 0.3)',
        }
    };

    const keyLogoStyles = {
        marginRight: '10px',
        width: '50px',
        height: '25px',
    };

    const keyLogoTitleStyles = {
        fontFamily: 'SF-Pro-Text-Thin, sans-serif',
        fontSize: '22px',
        marginTop: '8px',
        backgroundImage: 'linear-gradient(to bottom, #ffe656, #cdae00)',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        color: 'transparent'
    };
    

    const titleStylesThemed = {
        apple: {
            fontFamily: 'SF-Pro-Display-Thin, sans-serif',
            fontSize: '35px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'black',
            marginTop: '20px',
            marginBottom: '0px'
        },
        google: {
            fontFamily: 'ProductSansRegular, sans-serif',
            fontSize: '35px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'black',
            marginTop: '20px',
            marginBottom: '0px'
        }
    };

    const subtitleStylesThemed = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            color: isToggled ? 'rgb(190, 190, 190)' : 'rgb(92, 92, 92)',
            marginTop: '5px',
            marginBottom: '20px'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '20px',
            color: isToggled ? 'rgb(190, 190, 190)' : 'rgb(92, 92, 92)',
            marginTop: '5px',
            marginBottom: '20px'
        }
    };

    const projectDescriptionStyles = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'rgb(0, 0, 0)',
            marginTop: '10px',
            paddingLeft: '23px',
            paddingRight: '23px'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '21px',
            color: isToggled ? 'rgb(240, 240, 240)' : 'rgb(0, 0, 0)',
            marginTop: '10px',
            paddingLeft: '20px',
            paddingRight: '20px'
        }
    };

    const readVisitButtonsContainer = {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        marginTop: '10px',
        marginBottom: '120px'
    };
    
    const readMoreStyles = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: isToggled ? 'rgb(34, 34, 36)' : 'rgba(77, 41, 151, 0.8)',
            padding: '8px 12px',
            borderRadius: '20px',
            border: '1px solid rgba(180,180,180,0.3)'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '20px',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: isToggled ? 'rgb(34, 34, 36)' : 'rgba(77, 41, 151, 0.7)',
            padding: '4px 12px',
            borderRadius: '20px',
            border: '1px solid rgba(180,180,180,0.3)'
        }
    };

    const visitSiteStyle = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: isToggled ? 'rgb(34, 34, 36)' : 'rgba(77, 41, 151, 0.8)',
            padding: '8px 12px',
            borderRadius: '20px',
            border: '1px solid rgba(180,180,180,0.3)'
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '20px',
            cursor: 'pointer',
            color: 'white',
            backgroundColor: isToggled ? 'rgb(34, 34, 36)' : 'rgba(77, 41, 151, 0.7)',
            padding: '4px 12px',
            borderRadius: '20px',
            border: '1px solid rgba(180,180,180,0.3)'
        }
    };

    const toolStyles = {
        list: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
        },
        image: {
            width: '30px',
            height: '30px'
        }
    };

    return(

        <div style={projectOneContainer}>

            <div style={logoContainerStyles[theme]}>
                <img src='AppContent/KeyLogoTransparent.png' style={keyLogoStyles} alt='pc-logo' />
                <h3 style={keyLogoTitleStyles}>PocketCipher</h3>
            </div>

            <h2 style={titleStylesThemed[theme]}>Product Landing Page</h2>
            <h2 style={subtitleStylesThemed[theme]}>Web Application</h2>
            <p style={projectDescriptionStyles[theme]}>
                Landing page built with React for the purpose of providing the legal and contact
                information required for the PocketCipher mobile application to be eligible for App Store deployment.
            </p>


            <div style={readVisitButtonsContainer}>
                <Link to="/pocketciphersite" style={{textDecoration: 'none'}}>
                    <button style={readMoreStyles[theme]}>read more</button>
                </Link>

                <a href='http://pocket-cipher-static-files.s3-website-us-east-1.amazonaws.com/'>
                    <button style={visitSiteStyle[theme]}>visit site &#8594;</button>
                </a> 
            </div>

            <div style={toolStyles['list']}>
                <img style={toolStyles['image']} src="SkillsLogos/javascript.png" alt="Skill4" />
                <img style={toolStyles['image']} src="SkillsLogos/html.png" alt="Skill10" />
                <img style={toolStyles['image']} src="SkillsLogos/css.png" alt="Skill6" />
                <img style={toolStyles['image']} src="SkillsLogos/react.png" alt="Skill8" />
                <img style={toolStyles['image']} src="SkillsLogos/vscode.webp" alt="Skill9" />
                
            </div>




        </div>


    );

};

export default ProjectCardOne;