import CardBorderSVG from '../../utility/CardBorderSVG';

const JobCard = ({ isToggled, theme, cardContent }) => {

    const jobContainerStyles = {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '600px',
        minWidth: '500px',
        height: '300px',
        borderRadius: '28px',
        transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
        zIndex: '1'
    };

    const jobContainerStylesThemed = {
        apple: {
            ...jobContainerStyles,
            backgroundColor: isToggled
            ? 'rgb(44, 44, 46)'
            : 'rgb(255, 255, 255)',
            boxShadow: isToggled
                ? '0px 0px 15px rgba(0, 0, 0, 0.3)'
                : '0px 0px 15px rgba(0, 0, 0, 0.3)',
        },
        google: {
            ...jobContainerStyles,
            background: isToggled ? 'radial-gradient(circle, rgb(40, 49, 66), rgb(27, 33, 45))' 
            : 'radial-gradient(circle, rgb(212, 214, 250), rgb(230, 231, 255))',
        }
    };

    const titleDateContainerStyles = {
        height: '20px',
        display: 'flex',
        paddingTop: '10px',
        paddingLeft: '20px',
        paddingBottom: '15px',
        alignItems: 'baseline',
    }

    const jobTitleStyles = {
        apple: {
            fontFamily: 'SF-Pro-Regular, sans-serif',
            fontSize: '30px',
            margin: 0,
            color: isToggled
                ? 'rgb(255, 255, 255)'
                : 'rgb(44, 44, 46)',
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '30px',
            margin: 0,
             color: isToggled ? 'rgb(186, 204, 255)' : 'rgb(36, 148, 255)'
        }
        
    };

    const infoDateContainerStyles = {
        height: '20px',
        display: 'flex',
        paddingTop: '25px',
        paddingLeft: '20px',
        paddingBottom: '15px',
        alignItems: 'baseline',
    }

    const jobInfoStyles = {
        apple: {
            fontFamily: 'SF-Pro-Regular, sans-serif',
            fontSize: '22px',
            margin: 0,
            color: isToggled
                ? 'rgb(205, 205, 205)'
                : 'rgb(94, 94, 96)',
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '22px',
            margin: 0,
             color: isToggled ? 'rgb(168, 190, 255)' : 'rgb(45, 149, 247)'
        }
        
    };

    const dateStyles = {
        apple: {
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '20px',
            margin: 0,
            marginLeft: '10px',
            color: isToggled
                ? 'rgb(205, 205, 205)'
                : 'rgb(44, 44, 46)',
        },
        google: {
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '20px',
            margin: 0,
            marginLeft: '10px',
            color: isToggled
                ? 'rgb(255, 255, 255)'
                : 'rgb(44, 44, 46)',
        }
    };

    const listItemStyles = {
        apple: {
            fontFamily: 'SF-Pro-Regular, sans-serif',
            fontSize: '18px',
            color: isToggled ? 'white' : 'black',
            marginBottom: '7px'
        },
        google: {
            fontFamily: 'ProductSansRegular, sans-serif',
            fontSize: '18px',
            color: isToggled ? 'white' : 'black',
            marginBottom: '7px'
        }
    };

    return(
        <div style={jobContainerStylesThemed[theme]}>

            {theme === 'google' ? <CardBorderSVG isToggled={isToggled} cardType={'profile'} /> : null}

            <div style={titleDateContainerStyles}>
                <h2 style={jobTitleStyles[theme]}>{cardContent[0]}</h2>
                <h3 style={dateStyles[theme]}>{cardContent[1]}</h3>
            </div>

            <div style={infoDateContainerStyles}>
                <h2 style={jobInfoStyles[theme]}>{cardContent[2]}</h2>
                <h3 style={dateStyles[theme]}>{cardContent[3]}</h3>
            </div>

            <ul style={{marginTop: '20px', textAlign: 'start'}}>
                <li style={listItemStyles[theme]}>{cardContent[4]}</li>
                <li style={listItemStyles[theme]}>{cardContent[5]}</li>
                <li style={listItemStyles[theme]}>{cardContent[6]}</li>
                <li style={listItemStyles[theme]}>{cardContent[7]}</li>
                {cardContent[8] && <li style={listItemStyles[theme]}>{cardContent[8]}</li>}
            </ul>

        </div>
    );
};

export default JobCard;