import '../../styles/ProjectPages/ProjectTwoPage.css';
import '../../styles/ProjectPages/ProjectOnePage.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const ProjectTwoPage = ({ isToggled, theme }) => {

    useEffect(() => {
        window.scrollTo(0, 0); // Reset scroll position when this component mounts
        }, []);

    //back button
    const backButtonStyles = {
        apple: {
            background: 'none',
            border: 'none',
            color: 'rgb(10, 132, 255)',
            fontSize: '20px',
            cursor: 'pointer'
        },
        google: {
            background: 'none',
            border: 'none',
            color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(255, 255, 255)',
            fontSize: '20px',
            cursor: 'pointer'
        },
        anon: {
            marginTop: '20px',
            fontFamily: 'unicaone, sans-serif',
            position: 'relative',
            zIndex: 2,
            fontSize: '18px',
            cursor: 'pointer',
            backgroundColor: (isToggled ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.8'), 
            color: (isToggled ? 'black' : 'white'),
            border: isToggled ? '2px solid black': '2px solid white',
        }
    };

    const projectOnePageStyles = {
        apple: {
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
            width: '1000px',
            minWidth: '900px',
            paddingBottom: '50px',
            borderRadius: '30px',
            backgroundColor: isToggled ? 'rgb(44, 44, 46)' : 'rgb(255, 255, 255)',
            boxShadow: isToggled ? '0px 0px 25px rgba(0, 0, 0, 0.3)' : '0px 0px 25px rgba(0, 0, 0, 0.3)',
            transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
        },
        google: {
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'column',
            width: '1000px',
            minWidth: '900px',
            paddingBottom: '50px',
            borderRadius: '30px',
            backgroundColor: isToggled ? 'rgb(33, 39, 54)' : 'rgb(230, 231, 255)',
            boxShadow: isToggled ? '0px 0px 25px rgba(0, 0, 0, 0.3)' : '0px 0px 25px rgba(0, 0, 0, 0.3)',
            transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
        },
        anon: {
            zIndex: '2',
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            width: '1000px',
            minWidth: '900px',
            paddingBottom: '50px',
            marginBottom: '30px',
            backgroundColor: isToggled ? 'rgba(19, 19, 19, 0.9)' : 'rgba(255, 255, 255, 0.9)',
            border: `2px solid ${isToggled ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.8)'}`,
            boxShadow: isToggled
                ? '0px 0px 25px rgba(0, 0, 0, 0.9)'
                : '0px 0px 25px rgba(0, 0, 0, 0.9)',
            transition: 'background-color 0.3s ease, box-shadow 0.5s ease',
        }
    };

    const appNameStyles = {
        apple: {
            fontFamily:'SF-Pro-Text-Thin, sans-serif',
            fontSize: '30px',
            margin: 0,
            color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(44, 44, 46)',
        },
        google :{
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '30px',
            margin: 0,
            color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(44, 44, 46)',
        },
        anon: {
            fontFamily: 'unicaone, sans-serif',
            textTransform: 'uppercase',
            fontSize: '30px',
            margin: 0,
            color: isToggled ? 'rgb(230, 230, 230)' : 'rgb(44, 44, 46)',
        }
    };

    const appDescriptionStyles = {
        apple: {
            fontFamily:'SF-Pro-Text-Thin, sans-serif',
            fontSize: '15px',
            margin: 0,
            color: isToggled ? 'rgb(180, 180, 180)' : 'rgb(44, 44, 46)',
        },
        google :{
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '15px',
            margin: 0,
            color: isToggled ? 'rgb(180, 180, 180)' : 'rgb(44, 44, 46)',
        },
        anon: {
            fontFamily: 'unicaone, sans-serif',
            textTransform: 'uppercase',
            fontSize: '15px',
            margin: 0,
            color: isToggled ? 'rgb(180, 180, 180)' : 'rgb(44, 44, 46)',
        }
    };

    const dividerStyles = {
        margin: '40px auto 20px auto',
        height: theme === 'apple' ? '2px' : '1px',
        borderRadius: '3px',
        width: '800px',
        backgroundColor: isToggled
            ? 'rgb(60, 60, 60)'
            : 'rgb(180, 180, 180)',
    };

    const verboseDescriptionStyles = {
        apple: {
            flex: 1,
            fontFamily: 'SF-Pro-Text-Thin, sans-serif',
            fontSize: '19px',
            padding: '0px 20px',
            color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(0, 0, 0)',
        },
        google: {
            flex: 1,
            fontFamily: 'ProductSansLight, sans-serif',
            fontSize: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(0, 0, 0)',
        },
        anon: {
            flex: 1,
            fontFamily: 'unicaone, sans-serif',
            textTransform: 'uppercase',
            fontSize: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(0, 0, 0)',
        }
        
    };

    //INFO STYLES ------------
    const appInfoContainer = {
        apple: {
            flex: 1,
            backgroundColor: isToggled ? 'rgba(0,0,0,0.15)' : 'rgba(0,0,0,0.1)',
            borderRadius: '20px',
            height: '280px',
            paddingTop: '12px',
        },
        google: {
            flex: 1,
            height: '280px',
            paddingTop: '12px',
            backgroundColor: isToggled ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.1)',
            borderRadius: '20px'
        },
        anon: {
            flex: 1,
            height: '280px',
            paddingTop: '12px',
            backgroundColor: isToggled ? 'rgba(0,0,0,0.6)' : 'rgba(210,210,210,0.8)',
            border: isToggled ? '1px solid rgba(200,200,200,0.6)' : '1px solid rgba(0,0,0,0.5)',
        }
    };

    const infoElementStyles = {
        display: 'flex',
        height: '40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '25px',
        paddingRight: '25px'
    };

    const fonts = {
        apple: 'SF-Pro-Text-Thin, sans-serif',
        google: 'ProductSansLight, sans-serif',
        anon: 'unicaone, sans-serif'
    };

    const infoTextLeft = {
        fontFamily: fonts[theme],
        textTransform: theme === 'anon' ? 'uppercase' : 'none',
        fontSize: '14px',
        color: isToggled ? 'gray' : 'rgb(80,80,80)',
        margin: 0,
    };

    const infoTextRight = {
        fontFamily: fonts[theme],
        textTransform: theme === 'anon' ? 'uppercase' : 'none',
        fontSize: '15px',
        color: isToggled ? 'white' : 'black',
        margin: 0,
    };

    const infoDividerStyles = {
        margin: '0px auto 0px auto',
        height: theme === 'anon' ? '1px' : '2px',
        borderRadius: '3px',
        width: '250px',
        backgroundColor: isToggled
            ? 'rgb(60, 60, 60)'
            : 'rgb(180, 180, 180)',
    };
    //END INFO STYLES ------------

    const sectionTitleStyles = {
        margin: '0px 0px 10px 100px',
        fontSize: '26px',
        fontFamily: fonts[theme],
        textTransform: theme === 'anon' ? 'uppercase' : 'none',
        color: isToggled ? 'rgb(235, 235, 235' : 'rgb(40,40,40)',
    };

    const sectionSubtitleStyles = {
        margin: '0px 0px 30px 100px',
        fontFamily: fonts[theme],
        textTransform: theme === 'anon' ? 'uppercase' : 'none',
        fontSize: '20px',
        color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(110,110,110)',
    };

    const myContributionStyles = {
        margin: '10px 100px 0px 100px',
        fontFamily: fonts[theme],
        textTransform: theme === 'anon' ? 'uppercase' : 'none',
        fontSize: '19px',
        lineHeight: '30px',
        color: isToggled ? 'rgb(185, 185, 185)' : 'rgb(0, 0, 0)' ,
    };

    return(
        <div className='back-page-container'>

            <Link to="/" style={{textDecoration: 'none', marginBottom: '10px', marginRight: '900px'}}>
                <button style={backButtonStyles[theme]}>&#x276E; back</button>
            </Link>

            <div style={projectOnePageStyles[theme]}>
                <div className='icon-name-container'> 
                    <img src='AppContent/JoystiqLogo.png' className='pc-page-logo' alt='appicon' />
                    
                    <div className='name-description-container'>
                        <h2 style={appNameStyles[theme]}>JOYSTIQ</h2>
                        <h3 style={appDescriptionStyles[theme]}>Social Media for Gamers</h3>
                        <div className='free-button' style={{borderRadius: theme === 'anon' ? '0px' : '15px'}}>
                            <p className='free-title'>Free</p>
                        </div>

                    </div> 
                </div>

                <div className='project-page-divider' style={dividerStyles} />

                <h2 style={sectionTitleStyles}>Information</h2>

                <div className='screenshot-information-container'>

                    <img src='AppContent/joystiqprocess/homescreen.png' className='js-app-screenshot' alt='appscreenshot' />

                    <div style={verboseDescriptionStyles[theme]}>
                        JOYSTIQ is an iOS application for people who love gaming.
                        Share your content in the form of awesome videos or enganging
                        discussions in personalized feeds. Compete against other gamers 
                        for a spot on the leaderboard. Find your gaming friends and
                        connect with new ones. Personalize your profile with your
                        best acheivments and your own avatar!
                    </div>

                    <div style={appInfoContainer[theme]}>
                    
                        <div style={infoElementStyles}>
                            <p style={infoTextLeft}>Seller</p>
                            <p style={infoTextRight}>JOYSTIQ LLC</p>
                        </div>
                        <div style={infoDividerStyles}/>

                        <div style={infoElementStyles}>
                            <p style={infoTextLeft}>Category</p>
                            <p style={infoTextRight}>Entertainment</p>
                        </div>
                        <div style={infoDividerStyles}/>

                        <div style={infoElementStyles}>
                            <p style={infoTextLeft}>Compatability</p>
                            <p style={infoTextRight}>iOS 16+</p>
                        </div>
                        <div style={infoDividerStyles}/>

                        <div style={infoElementStyles}>
                            <p style={infoTextLeft}>Languages</p>
                            <p style={infoTextRight}>English</p>
                        </div>
                        <div style={infoDividerStyles}/>

                        <div style={infoElementStyles}>
                            <p style={infoTextLeft}>Age Rating</p>
                            <p style={infoTextRight}>12+</p>
                        </div>
                        <div style={infoDividerStyles}/>

                        <div style={infoElementStyles}>
                            <p style={infoTextLeft}>In-App Purchases</p>
                            <p style={infoTextRight}>Yes</p>
                        </div>

                    </div>
                </div>

                <div className='project-page-divider' style={dividerStyles} />

                <h2 style={sectionTitleStyles}>My Contribution</h2>

                <div className='contribution-tools-container'>
                    <p style={myContributionStyles}>
                        JOYSTIQ started with 3 friends who enjoyed
                        video games having a cool idea for an app. That vision quickly came to life as I began designing the
                        company's logos and theming using GIMP and created the user interface wireframes
                        with Balsamiq. I then used Swift to begin building early iterations of the miniumum
                        viable product, which we used extensively to get feedback early on. 

                        We wanted to include a feature for a 3D, video game-like avatar system that would really
                        gamify the app and give users another way of personalizing their profiles. I organized
                        an iternship program and outlined several objectives for the 9 talented artists who were selected for
                        the program. I divided them into teams and met with both teams several times every week 
                        to ensure timely progress. By the end of the summer, the program had proved
                        to be extremely productive. We now had numerous 3D modeled app assets and avatars made with Blender which
                        I will showcase below. 
                        
                        I then collaborated closely with my brother using the Kanban development approach to make 
                        sure we were on track to release our beta by fall. He stood up a snappy backened while I 
                        continued to iterate on the frontend, steadily optimizing the interface components
                        and improving on designs. We were soon ready for TestFlight, so I built a landing 
                        page with React to provide legal and contact information and to direct potential beta 
                        testers to our discord server. We were grateful to have several dozen users on the platform testing features
                        and providing useful feedback in our discord. We hope to be app store ready soon!
                    </p>

                    <div className='tool-container'>
                        <img className='tool-big-image' src="SkillsLogos/swift.png" alt="Skill4" />
                        <img className='tool-big-image' src="SkillsLogos/xcode.png" alt="Skill6" />
                        <img className='tool-big-gimp' style={{marginLeft: '-15px', marginRight: '-15px'}} src="SkillsLogos/gimp.png" alt="Skill9" />
                        <img className='tool-big-image' src="SkillsLogos/balsamiq.png" alt="Skill8" />
                        <img className='tool-big-image' style={{width: '60px',marginLeft: '-15px', marginRight: '-15px'}} src="SkillsLogos/blender.png" alt="Skill8" />
                        <img className='tool-big-image' style={{borderRadius: '50%', background: 'gray', border: '1px solid gray'}} src="SkillsLogos/git.png" alt="Skill10" />    
                        <img className='tool-big-image' style={{marginLeft: '-10px'}} src="SkillsLogos/react.png" alt="Skill8" />
                    </div>
                </div>

                <div className='project-page-divider' style={dividerStyles} />

                <h2 style={sectionTitleStyles}>Logo Design with GIMP</h2>

                <div className='icon-iterations'>
                    <img src='AppContent/joystiqprocess/appicons/icon1.png' className='icon-image' alt='gimpimage' />
                    <p style={{margin: '20px 20px 0px 20px', color: isToggled? 'rgb(220,220,220)' : 'rgb(66,66,46)', fontSize: '40px'}}> &#8594; </p>
                    <img src='AppContent/joystiqprocess/appicons/icon2.png' className='icon-image' alt='gimpimage' />
                    <p style={{margin: '20px 20px 0px 20px', color: isToggled? 'rgb(220,220,220)' : 'rgb(66,66,46)', fontSize: '40px'}}> &#8594; </p>
                    <img src='AppContent/joystiqprocess/appicons/icon3.png' className='icon-image' alt='gimpimage' />
                </div>

                <div className='project-page-divider' style={dividerStyles} />

                <h2 style={sectionTitleStyles}>User Interface Wireframing with Balsamiq</h2>

                <div className='wireframe-container'>
                    <img src='AppContent/joystiqprocess/wireframes/home-wf.png' className='wireframe-image' alt='bsimage' />
                    <img src='AppContent/joystiqprocess/wireframes/ProfileFixed.png' className='wireframe-image' alt='bsimage' />
                    <img src='AppContent/joystiqprocess/wireframes/themeconcept.png' className='wireframe-image' alt='bsimage' />
                </div>

                <div className='project-page-divider' style={dividerStyles} />

                <h2 style={sectionTitleStyles}>From Wireframes to an M.V.P. with Swift</h2>

                <div className='homepage-iterations'>
                    <img src='AppContent/joystiqprocess/wireframes/home1.png' className='homepage-image' alt='gimpimage' />
                    <p style={{margin: '140px 10px 0px 10px', color: isToggled? 'rgb(220,220,220)' : 'rgb(66,66,46)', fontSize: '40px'}}> &#8594; </p>
                    <img src='AppContent/joystiqprocess/wireframes/home2.png' className='homepage-image' alt='gimpimage' />
                    <p style={{margin: '140px 10px 0px 10px', color: isToggled? 'rgb(220,220,220)' : 'rgb(66,66,46)', fontSize: '40px'}}> &#8594; </p>
                    <img src='AppContent/joystiqprocess/wireframes/home3.png' className='homepage-image' alt='gimpimage' />
                </div>

                <div className='project-page-divider' style={dividerStyles} />

                <h2 style={sectionTitleStyles}>3D Models with Blender</h2>
                <h3 style={sectionSubtitleStyles}>Deafult Masculine & Feminine Models</h3>

                <div className='avatar-image-container'>
                    <img src='AppContent/joystiqprocess/avatarstuff/ava1.png' className='ava-image' alt='avatarimage' />
                    <img src='AppContent/joystiqprocess/avatarstuff/ava2.png' className='ava-image' alt='avatarimage' />
                    <img src='AppContent/joystiqprocess/avatarstuff/ava4.png' className='fava-image' alt='avatarimage' />
                </div>

                <h3 style={{...sectionSubtitleStyles, margin: '40px 0px 25px 100px'}}>Cosmetic models for avatar customization</h3>

                <div className='cosmetic-image-container'>
                    <img src='AppContent/joystiqprocess/avatarstuff/cosmetic1.png' className='cosmetic-hair-image' alt='avatarimage' />
                    <img src='AppContent/joystiqprocess/avatarstuff/cosmetic2.png' className='cosmetic-image' alt='avatarimage' />
                    <img src='AppContent/joystiqprocess/avatarstuff/cosmetic3.png' className='cosmetic-tall-image' alt='avatarimage' />
                </div>

                <h3 style={{...sectionSubtitleStyles, margin: '40px 0px 25px 100px'}}>JOYSTIQ Accolades, Leaderboard Banners, & Profile Environment Samples</h3>

                <div className='medal-trophy-container'>
                    <img src='AppContent/joystiqprocess/assets/medalgold.png' className='medal-image' alt='accoladeimage' />
                    <img src='AppContent/joystiqprocess/assets/medalsilver.png' className='medal-image' alt='accoladeimage' />
                    <img src='AppContent/joystiqprocess/assets/medalbronze.png' className='medal-image' alt='accoladeimage' />
                    <img src='AppContent/joystiqprocess/assets/trophygold.png' className='trophy-image' alt='accoladeimage' />
                    <img src='AppContent/joystiqprocess/assets/trophysilver.png' className='trophy-image' alt='accoladeimage' />
                    <img src='AppContent/joystiqprocess/assets/trophybronze.png' className='trophy-image' alt='accoladeimage' />
                </div>

                <div className='banner-container'>
                    <img src='AppContent/joystiqprocess/assets/bannergold.png' className='banner-image' alt='accoladeimage' />
                    <img src='AppContent/joystiqprocess/assets/bannersilver.png' className='banner-image' alt='accoladeimage' />
                    <img src='AppContent/joystiqprocess/assets/bannerbronze.png' className='banner-image' alt='accoladeimage' />
                </div>

                <div className='environment-container'>
                    <img src='AppContent/joystiqprocess/assets/environment.png' className='env-image' alt='envimage' />
                    <img src='AppContent/joystiqprocess/assets/environment2.png' className='env-image' alt='envimage' />
                </div>

                <div className='project-page-divider' style={dividerStyles} />
                <h2 style={sectionTitleStyles}>TestFlight Beta</h2>
                <h3 style={{...sectionSubtitleStyles, margin: '0px 50px 30px 100px', paddingRight: '50px'}}>
                    Dual home feed for following & global posts, leaderboard with most popular content,
                    profiles with custom avatars
                </h3>

                <div className='wireframe-container'>
                    <img src='AppContent/joystiqprocess/homescreen.png' className='wireframe-image' alt='bsimage' />
                    <img src='AppContent/joystiqprocess/leaderboard.png' className='wireframe-image' alt='bsimage' />
                    <img src='AppContent/joystiqprocess/profile.png' className='wireframe-image' alt='bsimage' />
                </div>

                {/*
                <div className='project-page-divider' style={dividerStyles} />
                <h2 style={sectionTitleStyles}>Wardrobe Feature Demo</h2>
                <h3 style={betaSubtitleStyles}>Leveraging SceneKit, the iOS game development framework, I used the models from the 
                program to give users a game-like avatar experience</h3>


                <div className='video-container'>
                    <video controls width="480" height="800">
                        <source src="https://s3.amazonaws.com/connorsottosanti.com/AppContent/joystiqprocess/demo1.MP4"type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div className='project-page-divider' style={dividerStyles} />
                <h2 style={sectionTitleStyles}>App Demo (Volume Warning!)</h2>
                <h3 style={betaSubtitleStyles}>Here's a quick look at our beta</h3>


                <div className='video-container'>
                    <video controls width="480" height="800">
                        <source src="AppContent/joystiqprocess/demo2.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

                */}

            </div>
        </div>
            
    );
};

export default ProjectTwoPage;