import '../styles/HomePage.css'
import { useEffect } from 'react';
//Apple / Google Style Cards
import ProfileCard from './HomePageCards/ProfileCard';
import SkillsCard from './HomePageCards/SkillsCard';
import ProjectCardOne from './HomePageCards/ProjectCardOne';
import ProjectCardTwo from './HomePageCards/ProjectCardTwo';
import ProjectCardThree from './HomePageCards/ProjectCardThree';
import ProjectCardFour from './HomePageCards/ProjectCardFour';
import JobCard from './HomePageCards/JobCard';
import RutgersCard from './HomePageCards/RutgersCard';
import AnimatedLine from '../utility/AnimatedLine';
//Anon Style Cards
import AnonProfileCard from './HomePageCards/AnonCards/AnonProfileCard';
import AnonSkillsCard from './HomePageCards/AnonCards/AnonSkillsCard';
import JoystiqAppAnonCard from './HomePageCards/AnonCards/JoystiqAppAnonCard';
import PCAppAnonCard from './HomePageCards/AnonCards/PCAppAnonCard';
import JoystiqSiteAnonCard from './HomePageCards/AnonCards/JoystiqSiteAnonCard';
import PCSiteAnonCard from './HomePageCards/AnonCards/PCSiteAnonCard';
import JobCardAnon from './HomePageCards/AnonCards/JobCardAnon';


const HomePage = ({ isToggled, theme }) => {

  const intercoJobContent = ["Software Developer", "05/2024 - Current", "Interco - ", "St. Louis, MO",
    "Contributing to the development of a custom ERP web application",
    "Creating reusable React components aligned with Figma designs",
    "Led the development effort for the customer relationship management module",
    "Developed custom fishbowl reports tailored to the company's warehousing requirements"
 ];

  const joystiqJobContent = ["Software Engineer", "02/2023 - 03/2024", "JOYSTIQ LLC - ", "Hoboken, NJ",
     "Designed user interface wireframes with Figma and Balsamiq",
     "Translated wireframes into interactive user interfaces using Swift",
     "Led team of 9 interns to produce app assets and 3D models",
     "Deployed JOYSTIQ to TestFlight and PocketCipher to App Store",
     "Designed and developed multiple product landing pages"
  ];

  const cyberpointJobContent = ["Software Engineering Intern", "06/2022 - 08/2022", "Cyberpoint International - ", "Columbia, MD",
    "Developed the front-end of a forensic analysis application with React",
    "Designed reusable components using JavaScript, HTML, and CSS to create a dynamic user interface",
    "Collaborated with backend engineer for API integration",
    "Delivered functional SPA to serve as minimum viable product",
    "Utilized agile methodology, gave demos to senior management"
 ];

 const googleExtraStyles = {
    gap: '100px', 
    marginTop: '100px', 
    paddingBottom: '40px',
 };

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position when component mounts
    }, []);

  return (

    <>
      {theme === 'apple' || theme === 'google' ? (
        
        <div className="home-page-container">

          <div className='profile-skills-container'>
            <ProfileCard theme={theme} isToggled={isToggled} />
            <SkillsCard theme={theme} isToggled={isToggled} />
          </div>

          {theme === 'apple' ? 
            <div className={isToggled ? 'about-project-divider-dark' : 'about-project-divider-light'} /> 
            : 
            <>
              <AnimatedLine lineNumber={1} isToggled={isToggled} />
              <AnimatedLine lineNumber={2} isToggled={isToggled} />
            </>
          }

          <div className='js-projects-container' style={{marginTop: theme === 'google' ? '50px' : 'none'}}>
            <ProjectCardTwo theme={theme} isToggled={isToggled} />
            <ProjectCardOne theme={theme} isToggled={isToggled} />
          </div>

          <div className='pc-projects-container' style={{marginBottom: theme === 'google' ? '80px' : 'none'}}>
            <ProjectCardThree theme={theme} isToggled={isToggled} />
            <ProjectCardFour theme={theme} isToggled={isToggled} />
          </div>

          
          {theme === 'apple' && 
            <div className={isToggled ? 'project-experience-divider-dark' : 'project-experience-divider-light'} style={{marginTop: '80px'}} /> 
          }

          <div className='experience-container'>
            <JobCard cardContent={intercoJobContent} theme={theme} isToggled={isToggled} />
            <JobCard cardContent={joystiqJobContent} theme={theme} isToggled={isToggled} />
          </div>

          <div className='experience-container' style={theme === 'google' ? googleExtraStyles : null}>
            <JobCard cardContent={cyberpointJobContent} theme={theme} isToggled={isToggled} />
            <RutgersCard theme={theme} isToggled={isToggled} />
          </div>

        </div>
      ) : (

        <div className='home-page-anon-container'>

          {/*isToggled ? <ThreeDScene /> : <ThreeDSceneLight />*/}
          
          <div className='profile-skills-anon-container'>
            <AnonProfileCard isToggled={isToggled} />
            <AnonSkillsCard isToggled={isToggled} />
          </div>

          <div className='anon-divider-centerer'>
            <div className='anon-divider-container' >
              <div className='anon-divider-1' style={{ backgroundColor: isToggled ? 'white' : 'black', 
            border: (isToggled ? '2px solid white' : '2px solid black' )}} />
              <div className='anon-divider-2' style={{ backgroundColor: isToggled ? 'white' : 'black'}} />
              <div className='anon-divider-1' style={{ backgroundColor: isToggled ? 'white' : 'black', 
            border: (isToggled ? '2px solid white' : '2px solid black' )}} />
            </div>
          </div>

          <div className='js-projects-anon-container'>
            <JoystiqAppAnonCard isToggled={isToggled} />
            <PCAppAnonCard isToggled={isToggled} />
          </div>

          <div className='pc-projects-anon-container'>
            <JoystiqSiteAnonCard isToggled={isToggled} />
            <PCSiteAnonCard isToggled={isToggled} />
          </div>

          <div className='anon-divider-centerer'>
            <div className='anon-divider-container' >
              <div className='anon-divider-1' style={{ backgroundColor: isToggled ? 'white' : 'black', 
            border: (isToggled ? '2px solid white' : '2px solid black' )}} />
              <div className='anon-divider-2' style={{ backgroundColor: isToggled ? 'white' : 'black'}} />
              <div className='anon-divider-1' style={{ backgroundColor: isToggled ? 'white' : 'black', 
            border: (isToggled ? '2px solid white' : '2px solid black' )}} />
            </div>
          </div>

          <div className='experience-container-anon'>
            <JobCardAnon cardContent={intercoJobContent} isToggled={isToggled} />
            <JobCardAnon cardContent={joystiqJobContent} isToggled={isToggled} />
          </div>

          <div className='experience-container'>
            <JobCardAnon cardContent={cyberpointJobContent} isToggled={isToggled} />
            <RutgersCard theme={theme} isToggled={isToggled} />
          </div>

        </div> 
      )}
    </>
  );
}

export default HomePage;